import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Button, ButtonArea, Dialog, Heading, Stack } from '../../../components/base';
import { AlertType } from '../../../libs/models/AlertType';
import { IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { showAlertToast } from '../../../redux/features/app/appSlice';

interface PluginViewerProps {
  pluginData: IPluginPromptData;
  isOpen: boolean;
  onClose: () => void;
  onBookmarkToggle: (id: string, bookmarked: boolean) => void;
}

const PluginViewer: React.FC<PluginViewerProps> = ({ pluginData, isOpen, onClose, onBookmarkToggle }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: RootState) => state.users);
  const {
    id,
    name,
    tags,
    model,
    bookmarkUserCount,
    profileUrl,
    description,
    createdTime,
    updatedTime,
    createdMember,
    bookmarkUserIds,
    conversationStarters,
    deleted,
  } = pluginData;

  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  // const [isToggling, setIsToggling] = useState(false); // API 호출 중인지 여부

  useEffect(() => {
    if (bookmarkUserIds) {
      setIsBookmarked(!!bookmarkUserIds.find((userId) => userId === user.userId));
      setBookmarkCount(Number(bookmarkUserCount));
    }
  }, [bookmarkUserIds, user.userId, bookmarkUserCount]);

  // useEffect(() => {
  //   // 북마크 상태가 변경될 때마다 count를 업데이트
  //   if (isBookmarked) {
  //     setBookmarkCount((prevCount) => prevCount + 1);
  //   } else {
  //     setBookmarkCount((prevCount) => prevCount - 1);
  //   }
  // }, [isBookmarked]);

  const handleBookmarkToggle = () => {
    // if (isToggling) return; // 이미 API 호출 중이면 함수 종료

    // setIsToggling(true); // API 호출 시작
    const localBookmark = !isBookmarked;
    setIsBookmarked(!isBookmarked);
    onBookmarkToggle(id as string, localBookmark);

    setBookmarkCount((prevCount) => (localBookmark ? prevCount + 1 : prevCount - 1));
    // API 호출 후 1초 후에 다시 활성화
    // setTimeout(() => {
    //   setIsToggling(false);
    // }, 1000);
  };

  const sharedPlugin = () => {
    const url = `${window.location.origin}/makers/plugin?sharedId=${pluginData.id}`;

    navigator.clipboard
      .writeText(url)
      .then(() => {
        dispatch(
          showAlertToast({
            altMessage: 'URL이 복사되었습니다.',
            altType: AlertType.Info,
          }),
        );
      })
      .catch((err) => {
        console.error('클립보드 복사 실패: ', err);
      });
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className="studio-read">
      <div className="read-detail">
        <div className="det-head">
          <Avatar size="5" src={profileUrl} label="프로필 이미지" />
          <div className="d-text">
            <Heading as="h2" size="title3" weight="semibold">
              {name}
            </Heading>
            <Stack>
              <Badge variant="tint" size="large" accent="primary">
                {model}
              </Badge>
            </Stack>
          </div>
          {!deleted && (
            <Stack className="d-utils">
              <Button
                variant="outline"
                prefixIcon={isBookmarked ? 'bookmarkFill' : 'bookmark'}
                onClick={handleBookmarkToggle}
                // disabled={isToggling} // API 호출 중이면 버튼 비활성화
              >
                {bookmarkCount}
              </Button>
              <Button variant="outline" iconOnly="share" onClick={sharedPlugin}>
                공유하기
              </Button>
            </Stack>
          )}
        </div>
        <div className="det-body">
          <p className="d-desc">{description}</p>
          {conversationStarters && conversationStarters.length > 0 && conversationStarters[0] !== '' && (
            <div className="dsx-title-bar">
              <Heading size="body2" weight="semibold">
                이런 질문을 할 수 있어요!
              </Heading>
            </div>
          )}
          {conversationStarters && conversationStarters.length > 0 && conversationStarters[0] !== '' && (
            <div className="dsx-cont-box question-list">
              {conversationStarters.map((starter, index) => (
                <p key={index} className="quest">
                  {starter}
                </p>
              ))}
            </div>
          )}
          <div className="d-keyword">
            {tags?.map((tag: string, index: number) => (
              <Badge key={index} variant="outlined" size="xlarge" accent="primary">
                # {tag}
              </Badge>
            ))}
          </div>
        </div>
        <div className="det-foot">
          <ul className="d-date">
            <li>
              <span className="tit">생성</span>
              <span className="con">{moment(createdTime as Date).format('YYYY-MM-DD')}</span>
            </li>
            <li>
              <span className="tit">업데이트</span>
              <span className="con">{moment(updatedTime as Date).format('YYYY-MM-DD')}</span>
            </li>
          </ul>
          <div className="d-user">
            <span className="dsx-avatar-info">
              <Avatar
                size="1"
                src={`https://gspaceapi.kt.co.kr/upload/profile/${createdMember?.userId}.jpg `}
                label=""
              />
              {createdMember?.name}
            </span>
          </div>
        </div>
        <ButtonArea align="full">
          <Button size="large" variant="outline" onClick={onClose}>
            닫기
          </Button>
          <Button
            size="large"
            variant="primary"
            onClick={() => {
              //onBookmarkToggle(id as string, true);
              const popup = window.opener as Window;
              if (popup) {
                popup.setPlugin(pluginData);
                window.close();
              } else {
                window.location.href = 'https://works.ktds.co.kr/group/main';
              }
            }}
            disabled={!!deleted}
          >
            플러그인 사용하기
          </Button>
        </ButtonArea>
      </div>
    </Dialog>
  );
};

export default PluginViewer;
