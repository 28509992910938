import React from 'react';
import { IChatKeyword } from '../../libs/models/conversations/ChatKeyword';
import { Button } from '../../components/Button';
import { Tag } from '../../components/Tag';

interface ChatKeywordProps {
  data: IChatKeyword[] | undefined;
  isDisabled: boolean;
  sendMessage: (item: IChatKeyword) => void;
  loadChatKeyword?: () => void;
}

export const ChatKeyword: React.FC<ChatKeywordProps> = ({ data, isDisabled, sendMessage, loadChatKeyword }) => {
  return (
    <div className="chat-keyword">
      <h2 className="blind">추천 키워드</h2>
      <div className="keyword-list">
        {data?.map((item, index) => (
            <div key={index} className="keyword-item">
              <a
                href="#"
                role="button"
                className={`chat-button keyword-link ${isDisabled ? 'disabled' : ''}`}
                onClick={() => {
                  sendMessage(item);
                }}
              >
                {item.isRecommend && <Tag>추천</Tag>} {item.content}
              </a>
            </div>
          ))}

        {loadChatKeyword && (
          <Button
            className="keyword-refresh"
            icon="recycle"
            control
            textBlind
            onClick={() => {
              loadChatKeyword();
            }}
          >
            새로고침
          </Button>
        )}
      </div>
    </div>
  );
};
