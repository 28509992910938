import { IChatMessageList } from './ChatMessage';

export enum ChatRoomType {
    ChatRoom = 'ChatRoom',
    TestRoom = 'TestRoom',
}

export interface IChatRoomList {
    continuationToken: string | null;
    data: IChatRoom[];
}

export interface IChatRoom {
    id: string;
    title: string;
    type: ChatRoomType;
    userId: string;
    createdTime: Date;
    updatedTime: Date;
    messages: IChatMessageList;
    botResponseStatus: boolean;
    isTyping: boolean;
    partitionKey: string;
}
