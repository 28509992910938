import React from 'react';
import classNames from 'classnames';

// Icon 컴포넌트의 Props 타입 정의
interface IconProps extends React.HTMLProps<HTMLElement> {
  name: string;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ name, className, ...props }) => {
  return (
    <i
      className={classNames('dsx-Icon', `dsx-Icon--${name}`, className)} // className이 null일 때 빈 문자열을 사용
      {...props}
    ></i>
  );
};

export default Icon;
