import React, { forwardRef } from 'react';
import classNames from 'classnames';
import Icon from './Icon';

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to?: string;
  href?: string;
  size?: 'body1' | 'body2' | 'body3' | 'label1' | 'label2' | 'caption1' | 'caption2';
  weight?: 'bold' | 'medium' | 'regular';
  underline?: 'hover' | 'always';
  accent?: 'primary' | 'positive' | 'negative' | 'caution' | 'info';
  prefixIcon?: string;
  suffixIcon?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children: React.ReactNode;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { to, href, size, weight, underline, accent, children, prefixIcon, suffixIcon, className, onClick, ...props },
    ref,
  ) => {
    const Tag = 'a';
    const isInternal = !!to;
    const isExternal = !!href && href.startsWith('http');
    const tagProps = { href: to ?? href };

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (isInternal) {
        e.preventDefault();
        window.history.pushState({}, '', to);
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      }
      if (onClick) onClick(e);
    };

    return (
      <Tag
        {...tagProps}
        role="button"
        className={classNames(
          'dsx-Link',
          size && `dsx-Text--${size}`,
          weight && `dsx-weight-${weight}`,
          underline && `dsx-underline-${underline}`,
          accent && `dsx-color-${accent}`,
          className,
        )}
        target={isExternal ? '_blank' : undefined}
        title={isExternal ? '새창열림' : undefined}
        ref={ref}
        onClick={isInternal ? handleClick : onClick}
        tabIndex={(to ?? href) ? undefined : 0}
        {...props}
      >
        {prefixIcon && <Icon name={prefixIcon} />}
        {children}
        {suffixIcon && <Icon name={suffixIcon} />}
      </Tag>
    );
  },
);

Link.displayName = 'Link';
