// import { ReactComponent as GPT } from '../../../assets/etc-icons/icon_gpt_model.svg';

export type AIModels = Record<string, AIModel>;

export interface AIModelAPI {
  model: AIModelName;
  displayName: string;
  description: string;
  isAvailable: boolean;
  vendor: string;
  profileUrl: string;
  usage: IUsage;
}

export interface AIModel {
  model: string;
  displayName: string;
  description: string;
  isAvailable: boolean;
  vendor: string;
  usage?: IUsage;
  profileUrl: string;
  tags?: string[];
  // icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export enum AIModelName {
  GPT_3_5 = 'GPT-3.5',
  GPT_4 = 'GPT-4',
  GPT_4O = 'GPT-4o',
  GPT_4O_Mini = 'GPT-4o-mini',
}

export interface AIModelsState {
  models: AIModels;
  selectedModel: AIModel;
}

export const initialState: AIModelsState = {
  models: {},
  selectedModel: {
    model: AIModelName.GPT_4O_Mini,
    description: '답변을 빠르게 해줘요.',
    displayName: 'GPT-4o-mini',
    isAvailable: true,
    vendor: 'AzureOpenAI',
    profileUrl: '/assets/etc-icons/icon_gpt_model.svg',
    usage: {
      modelName: AIModelName.GPT_4O_Mini,
      totalCount: 10,
      remainCount: 10,
      useCount: 0,
    },
  },
};

export const AIModels: AIModel[] = [
  {
    model: AIModelName.GPT_3_5,
    description: '답변을 빠르게 해줘요.',
    displayName: 'GPT-3.5',
    isAvailable: true,
    vendor: 'AzureOpenAI',
    profileUrl: '',
    // icon: GPT,
  },
  {
    model: AIModelName.GPT_4,
    description: '정확한 답변을 해줘요. 아직까지는 사용량에 제한이 있어요.',
    displayName: 'GPT-4',
    isAvailable: true,
    vendor: 'AzureOpenAI',
    profileUrl: '',
    // icon: GPT,
  },
  {
    model: AIModelName.GPT_4O,
    description: '최신 모델이며 빠르고 보다 정확한 답변을 해줘요. 아직까지는 사용량에 제한이 있어요.',
    displayName: 'GPT-4O',
    isAvailable: true,
    vendor: 'AzureOpenAI',
    profileUrl: '',
    // icon: GPT,
  },
];

export interface IUsage {
  userId?: string;
  modelName: string;
  totalCount?: number;
  useCount: number;
  remainCount?: number;
  date?: string;
}
