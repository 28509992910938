import classNames from 'classnames';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Tag } from './Tag';
import { Text } from './Text';

interface InputHashtagProps {
  size?: 'small' | 'large' | 'xlarge';
  variant?: 'outlined' | 'filled';
  placeholder?: string;
  value?: string[];
  className?: string;
  style?: React.CSSProperties;
  invalid?: boolean;
  maxTags?: number;
  showCount?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onChange?: (updatedTags: string[]) => void;
  name: string;
}

export const InputHashtag = forwardRef<HTMLDivElement, InputHashtagProps>(function InputHashtag(
  {
    variant = 'outlined',
    size,
    placeholder,
    value = [],
    className,
    style,
    invalid,
    onFocus,
    onBlur,
    onClick,
    maxTags,
    showCount,
    onChange,
    name,
    ...props
  },
  ref,
) {
  const [inputValue, setInputValue] = useState('');
  const [hashtags, setHashtags] = useState<string[]>(value);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setHashtags(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      if (!maxTags || hashtags.length < maxTags) {
        const updatedTags = [...hashtags, inputValue.trim()];
        setHashtags(updatedTags);
        setInputValue('');
        if (onChange) {
          onChange(updatedTags);
        }
      }
    }
  };

  const removeHashtag = (index: number) => {
    const updatedTags = hashtags.filter((_, i) => i !== index);
    setHashtags(updatedTags);
    if (onChange) {
      onChange(updatedTags);
    }
  };

  const focusInput = (event: React.MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLElement).tagName.toLowerCase() !== 'button') {
      inputRef.current?.focus();
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <>
      <div
        className={classNames(
          'dsx-Input dsx-InputHashtag',
          size && `dsx-Input--${size}`,
          `dsx-Input--${variant}`,
          invalid && 'is-invalid',
          className,
        )}
        style={style}
        onClick={focusInput}
        ref={ref}
      >
        {hashtags.length > 0 && (
          <div className="dsx-TagGroup">
            {hashtags.map((tag, index) => (
              <Tag
                key={index}
                variant="inverse"
                label={`# ${tag}`}
                closable
                onRemove={() => {
                  removeHashtag(index);
                }}
              />
            ))}
          </div>
        )}
        <input
          type="text"
          className="dsx-Input-field"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={inputRef}
          name={name}
          maxLength={16}
          {...props}
        />
      </div>
      {showCount && (
        <Text variant="length">
          {hashtags.length}/{maxTags}
        </Text>
      )}
    </>
  );
});
