import { useState, useEffect, ChangeEvent, forwardRef } from 'react';

interface ITextareaProps {
  selectedId: string;
  placeholder?: string;
  maxRows?: number;
  value: string;
  setValue?: (value: string) => void;
  isOpen: boolean;
  setSearchQuery: (value: string) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void; // Key down event handler
}

export const Textarea = forwardRef<HTMLTextAreaElement, ITextareaProps>(
  ({ selectedId, placeholder, maxRows = 8, value, setValue, isOpen, setSearchQuery, handleKeyDown }, ref) => {
    const [rows, setRows] = useState(1);

    const textAreaRef = ref as React.RefObject<HTMLTextAreaElement>;

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const textareaLineHeight = 24;
      const previousRows = event.target.rows;

      const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight); // 현재 행 수 계산
      const inputValue = event.target.value; // 현재 입력값

      if (isOpen) {
        setSearchQuery('');

        const cursorPosition = event.target.selectionStart; // 현재 커서 위치
        const textBeforeCursor = inputValue.slice(0, cursorPosition); // 커서 이전의 텍스트

        // 현재 커서 위치에서 마지막 @의 위치 찾기
        const lastHashIndex = textBeforeCursor.lastIndexOf('@');

        if (lastHashIndex !== -1) {
          const termAfterHash = inputValue.slice(lastHashIndex + 1, cursorPosition);
          setSearchQuery(termAfterHash);
        }
      }

      if (currentRows === previousRows) {
        event.target.rows = currentRows;
      }

      if (currentRows >= maxRows) {
        event.target.rows = maxRows;
        event.target.scrollTop = event.target.scrollHeight;
      }

      if (setValue) {
        setValue(inputValue);
        setRows(currentRows < maxRows ? currentRows : maxRows);
      }
    };

    useEffect(() => {
      const textarea = textAreaRef.current;
      if (textarea) {
        const textareaLineHeight = 24;
        const currentRows = Math.floor(textarea.scrollHeight / textareaLineHeight);
        setRows(currentRows < maxRows ? currentRows : maxRows);
      }
    }, [maxRows]);

    useEffect(() => {
      // Focus on the text area when the selected conversation changes
      if (setValue) setValue('');
      setRows(1);
    }, [selectedId]);

    useEffect(() => {
      if (textAreaRef.current) {
        const event: ChangeEvent<HTMLTextAreaElement> = {
          ...new Event('input', { bubbles: true }),
          target: textAreaRef.current,
          currentTarget: textAreaRef.current,
        } as unknown as ChangeEvent<HTMLTextAreaElement>;

        textAreaRef.current.value = value;
        handleChange(event);
        if (value === '') setRows(1);
      }
    }, [value]);

    return (
      <textarea
        ref={textAreaRef}
        id="input-text"
        className="input-text"
        placeholder={placeholder}
        rows={rows}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        style={{ overflowY: rows === maxRows ? 'auto' : 'hidden' }}
      />
    );
  },
);

Textarea.displayName = 'Textarea';
