import React from 'react';
import classNames from 'classnames';

interface MarkProps extends React.HTMLProps<HTMLElement> {
  variant?: 'bold' | 'fill';
  accent?: 'primary' | 'positive' | 'negative' | 'caution' | 'info' | 'lime';
  className?: string;
}

export const Mark: React.FC<MarkProps> = ({ variant = 'bold', accent, children, className, ...props }) => {
  return (
    <mark
      className={classNames(
        'dsx-Mark',
        `dsx-Mark--${variant}`, // Always valid due to default value
        accent && `dsx-color-${accent}`,
        className,
      )}
      {...props}
    >
      {children}
    </mark>
  );
};
