import { useState } from 'react';
import { Button, ButtonArea, Form, FormItem, InputHashtag } from '../../../components/base';
import RadioGroup from '../../../components/base/RadioGroup';
import { EOpenScopeType, IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';

const options = [
  {
    value: 'Public',
    label: '전체공개',
  },
  {
    value: 'Private',
    label: '나만보기',
  },
  {
    value: 'Part',
    label: '일부공개 (조직 선택 or 링크공유)',
    disabled: true,
  },
];

// export enum EOpenScopeType {
//   Public = '0',
//   Private = '1',
// }

interface IPromptCreateReg {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onPrev: () => void;
  onSubmit: () => void;
}

const PromptCreateReg: React.FC<IPromptCreateReg> = ({ formData, setFormData, onPrev, onSubmit }) => {
  const [errors, setErrors] = useState({
    openScope: '',
    tags: '',
  });

  const handleRadioChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    let openScope: EOpenScopeType | undefined;
    switch (value) {
      case 'Public':
        openScope = EOpenScopeType.Public;
        break;
      case 'Private':
        openScope = EOpenScopeType.Private;
        break;
      default:
        openScope = undefined;
    }

    if (openScope !== undefined) {
      setFormData((prev) => ({ ...prev, openScope }));
    }

    // setFormData((prev) => ({ ...prev, openScope: value }));
  };

  const handleHashtagChange = (newHashtags: string[]) => {
    setFormData((prev) => ({ ...prev, tags: newHashtags }));
  };

  const validate = () => {
    const newErrors = {
      openScope: '',
      tags: '',
    };
    if (!formData.openScope) newErrors.openScope = '공개 범위를 선택해주세요.';
    setErrors(newErrors);
    return Object.values(newErrors).every((value) => value.trim().length === 0);
  };

  const handlePrev = () => {
    onPrev();
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      onSubmit();
    }
  };

  return (
    <>
      <Form variant="vertical" className="form-field" onSubmit={handleFormSubmit}>
        <FormItem label="공개범위" error={errors.openScope} isRequired>
          <RadioGroup size="large" options={options} value={formData.openScope ?? ''} onChange={handleRadioChange} />
        </FormItem>
        <FormItem label="해시태그" desc="해시태그 입력은 최대 3개까지 가능해요">
          <InputHashtag
            size="xlarge"
            variant="filled"
            placeholder="단어 입력 후 엔터를 통해 해시 태그를 입력해 주세요"
            name="tags"
            value={formData.tags ?? []}
            onChange={handleHashtagChange}
            maxTags={3}
            showCount
          />
        </FormItem>
      </Form>
      <ButtonArea align="end">
        <div className="dsx-side-left">
          <Button variant="outline" size="large" prefixIcon="chevronLeft" onClick={handlePrev}>
            이전
          </Button>
        </div>
      </ButtonArea>
    </>
  );
};

export default PromptCreateReg;
