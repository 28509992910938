import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Empty, Link, Select, Text } from '../../components/base';
import { EOpenScopeType, IPluginPromptData } from '../../libs/models/studio/PluginPromptList';
import PluginPromptCard from './PluginPromptCard';
import { Constants } from '../../Constants';
import { useNavigate } from 'react-router-dom';
import Icon from '../../components/base/Icon';
import { v4 as uuidv4 } from 'uuid';

interface PluginPromptMyProps {
  myPluginState: IPluginPromptData[] | undefined;
  myPromptState: IPluginPromptData[] | undefined;
  hasMore: boolean;
  loadMore: () => void;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
  formValues: any;
  totalCount: number;
  menuLabel: string;
  menuType: 'plugin' | 'prompt';
  scrollViewTargetRef: React.RefObject<HTMLDivElement>;
  handleCardClick: (item: IPluginPromptData) => void;
  handleRestore: (menuType: string, id: string) => void;
  handleDelete: (menuType: string, id: string) => void;
  handleRemove: (menuType: string, id: string) => void;
  handleBookmark: (menuType: string, id: string, bookmarked: boolean) => void;
  handleChangeOpenScope: (menuType: 'plugin' | 'prompt', id: string, openScope: EOpenScopeType) => void;
  activeTabKey: string;
  selectedSort: string;
  handleSort: (value: string | number) => void;
  isDisabled: boolean;
  count: number;
  isLoading: boolean;
  onBookmarkToggle: (id: string, bookmarked: boolean) => void;
  setMyPluginState: React.Dispatch<React.SetStateAction<IPluginPromptData[]>>;
  setMyPromptState: React.Dispatch<React.SetStateAction<IPluginPromptData[]>>;
}

const PluginPromptMy: React.FC<PluginPromptMyProps> = ({
  myPluginState = [], // 기본값 빈 배열
  myPromptState = [], // 기본값 빈 배열
  hasMore,
  loadMore,
  totalCount,
  menuLabel,
  menuType,
  scrollViewTargetRef,
  handleCardClick,
  handleRestore,
  handleDelete,
  handleRemove,
  // handleBookmark,
  handleChangeOpenScope,
  activeTabKey,
  selectedSort,
  handleSort,
  isDisabled,
  count,
  isLoading,
  onBookmarkToggle,
  setMyPluginState,
  setMyPromptState,
}) => {
  const listToRender = menuType === 'plugin' ? myPluginState : myPromptState;

  const navigation = useNavigate();

  return (
    <>
      <div className="setting-bar">
        <Text size="body1" weight="medium" accent="neutral">
          총 {totalCount}개
        </Text>
        <div className="side">
          <Select
            size="large"
            placeholder="정렬 선택"
            options={Constants.orderbyList}
            value={selectedSort}
            onChange={handleSort}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="studio-card">
          {Array.from({ length: 12 }).map((_, index) => (
            <PluginPromptCard key={index + uuidv4()} menuType={menuType} isLoading={true} />
          ))}
        </div>
      ) : listToRender.length === 0 ? (
        <Empty
          image="images/ico_nodata.png"
          title={menuType === 'plugin' ? '플러그인이 없습니다.' : '프롬프트가 없습니다.'}
        />
      ) : (
        <InfiniteScroll
          loadMore={loadMore}
          initialLoad={false}
          hasMore={hasMore}
          isReverse={false}
          useWindow={false}
          getScrollParent={() => scrollViewTargetRef.current}
        >
          <div className="studio-card">
            <div className={`studio-card-item ${isDisabled ? 'is-disabled' : ''}`}>
              <Link
                onClick={() => {
                  if (isDisabled) return;
                  menuType === 'plugin'
                    ? navigation(`/makers/plugin/pluginCreate`)
                    : navigation(`/makers/prompt/promptCreate`);
                }}
                className="card-regist"
              >
                <strong className="r-tit">새 {menuLabel} 등록</strong>
                {isDisabled ? (
                  <span className="r-txt">
                    내일 다시 시도해 주세요. <em className="dsx-color-primary">(5/5)</em>
                  </span>
                ) : (
                  <span className="r-txt">
                    오늘 만든 {menuLabel}
                    <em className="dsx-color-primary">({count}/5)</em>
                  </span>
                )}
                <Icon name="plus" className="r-icon" />
              </Link>
            </div>
            {listToRender.map((item) => (
              <PluginPromptCard
                key={item.id + menuType}
                menuType={menuType}
                onRestore={handleRestore}
                onDelete={handleDelete}
                onRemove={handleRemove}
                // onBookmark={handleBookmark}
                onChangeOpenScope={handleChangeOpenScope}
                activeTabKey={activeTabKey}
                onCardClick={() => {
                  handleCardClick(item);
                }}
                setMyPluginState={setMyPluginState}
                setMyPromptState={setMyPromptState}
                onBookmarkToggle={onBookmarkToggle}
                {...item}
              />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

export default PluginPromptMy;
