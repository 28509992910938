import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { Constants } from './Constants';
import './index.css';
import { store } from './redux/app/store';
import './assets/font/NotoSansKr.css';
import './assets/font/NanumGothic.css';

if (!localStorage.getItem('debug')) {
  localStorage.setItem('debug', `${Constants.debug.root}:*`);
}

let container: HTMLElement | null = null;
let root: ReactDOM.Root | undefined = undefined;
// let msalInstance: PublicClientApplication | undefined;

document.addEventListener('DOMContentLoaded', () => {
  if (!container) {
    container = document.getElementById('root');
    if (!container) {
      throw new Error('Could not find root element');
    }
    root = ReactDOM.createRoot(container);

    renderApp();
  }
});

export function renderApp() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  root!.render(
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>,
  );
}
