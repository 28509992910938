import { FC, ReactNode } from 'react';
import classNames from 'classnames';

interface ITagProps {
  className?: string;
  children?: ReactNode;
  type?: string;
}

export const Tag: FC<ITagProps> = ({ className, children, type = 'primary', ...props }) => {
  return (
    <em className={classNames('ds-tag', `type-${type}`, className)} {...props}>
      {children}
    </em>
  );
};
