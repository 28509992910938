import { useState, useRef, forwardRef, ChangeEvent } from 'react';
import classNames from 'classnames';
import { Checkbox } from './Checkbox';

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
}

interface CheckboxGroupProps {
  name?: string;
  value?: string[];
  defaultValue?: string[];
  options: Option[];
  size?: string;
  invalid?: boolean;
  vertical?: boolean;
  className?: string;
  onChange?: (selectedValues: string[]) => void;
}

export const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>(
  (
    {
      name,
      value: valueProp,
      defaultValue = [],
      options,
      size = 'large',
      invalid,
      vertical,
      className,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(defaultValue);
    const controlled = useRef<boolean>(typeof valueProp !== 'undefined').current;
    const internalValues = (controlled ? valueProp : selectedValues) ?? [];

    const groupName = useRef<string>(name ?? `checkbox-group-${Math.random().toString(36).substring(2, 6)}`).current;

    const internalChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;
      let newValues: string[];

      if (checked) {
        newValues = [...internalValues, value];
      } else {
        newValues = internalValues.filter((v: string) => v !== value);
      }
      if (!controlled) {
        setSelectedValues(newValues);
      }
      if (onChange) {
        onChange(newValues);
      }
    };

    return (
      <div
        ref={ref}
        role="group"
        className={classNames('dsx-CheckboxGroup', vertical && 'dsx-align-vertical', className)}
        {...props}
      >
        {options.map((option) => (
          <Checkbox
            size={size}
            key={option.value}
            name={groupName}
            value={option.value}
            checked={internalValues.includes(option.value)}
            onChange={internalChange}
            disabled={option.disabled}
            invalid={invalid}
          >
            {option.label}
          </Checkbox>
        ))}
      </div>
    );
  },
);

CheckboxGroup.displayName = 'CheckboxGroup';
