import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle, ReactNode, RefObject } from 'react';

interface AnimationStateProps {
  isActive: boolean;
  nodeRef: RefObject<HTMLElement>;
  children: ReactNode;
  onStart?: () => void;
  onEnd?: () => void;
}

const useAnimationState = (isActive: boolean) => {
  const [animationState, setAnimationState] = useState<'show' | 'hide'>(isActive ? 'show' : 'hide');
  const [visible, setVisible] = useState(isActive);

  useEffect(() => {
    if (isActive) {
      setVisible(true);
      setAnimationState('show');
    } else {
      setAnimationState('hide');
    }
  }, [isActive]);

  const handleAnimationEnd = useCallback(() => {
    if (animationState === 'hide') {
      setVisible(false);
    }
  }, [animationState]);

  return { animationState, visible, handleAnimationEnd };
};

const AnimationState = forwardRef<HTMLDivElement, AnimationStateProps>(
  ({ isActive, nodeRef, children, onStart, onEnd }, ref) => {
    const { animationState, visible, handleAnimationEnd } = useAnimationState(isActive);

    useImperativeHandle(ref, () => nodeRef.current as HTMLDivElement, [nodeRef]);

    useEffect(() => {
      const node = nodeRef.current;

      if (node) {
        node.setAttribute('data-state', animationState);

        const onAnimationEnd = (e: AnimationEvent) => {
          if (e.target === node) {
            handleAnimationEnd();
            if (animationState === 'hide' && onEnd) {
              onEnd();
            }
          }
        };

        node.addEventListener('animationend', onAnimationEnd);

        if (animationState === 'show' && onStart) {
          onStart();
        }

        // 클린업 함수 반환
        return () => {
          node.removeEventListener('animationend', onAnimationEnd);
        };
      }

      // node가 null일 경우 빈 클린업 함수 반환
      return () => {};
    }, [animationState, handleAnimationEnd, nodeRef, onStart, onEnd]);

    if (!visible) return null;

    return React.cloneElement(children as React.ReactElement, {
      ref: nodeRef,
      'data-state': animationState,
    });
  },
);

AnimationState.displayName = 'AnimationState';

export default AnimationState;
