import { useState, useEffect, useRef, FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Button } from './Button';
type Position = 'top' | 'right' | 'bottom' | 'left';

interface IDropdownProps {
  className?: string;
  trigger?: ReactNode;
  position?: Position;
  children: ReactNode;
  changeState?: (state: boolean) => void;
  popoverClassName?: string;
  isOpen: boolean;
}

export const Dropdown: FC<IDropdownProps> = ({
  className,
  trigger,
  position = 'bottom',
  children,
  isOpen = false,
  changeState,
  popoverClassName,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (changeState) changeState(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    if (changeState) changeState(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    setIsDropdownOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleKeyPress = (event: { key: string }) => {
      if (event.key === 'Escape') {
        closeDropdown();
      }
    };

    if (isDropdownOpen) {
      window.addEventListener('keydown', handleKeyPress);
    } else {
      window.removeEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownOpen]);

  useEffect(() => {
    if (triggerRef.current) {
      const buttonOrLink = triggerRef.current.querySelector('button, a') as
        | HTMLButtonElement
        | HTMLAnchorElement
        | undefined;
      if (buttonOrLink) {
        buttonOrLink.onclick = handleDropdownToggle;
        buttonOrLink.setAttribute('aria-expanded', isDropdownOpen.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownOpen, trigger]);

  return (
    <div className={classNames('ds-dropdown', className)} ref={dropdownRef}>
      <div ref={triggerRef}>
        {trigger ? (
          trigger
        ) : (
          <Button
            className={classNames('ds-dropdown-trigger')}
            onClick={handleDropdownToggle}
            aria-expanded={isDropdownOpen}
          >
            Click
          </Button>
        )}
      </div>
      {isOpen && (
        <div
          className={classNames(
            'ds-popover',
            // position이 bottom 이 아니면, popover-position.charAt(0) 한다는 뜻, type때문에 길어짐
            {
              [`popover-${typeof position === 'string' ? position.charAt(0) : ''}`]:
                typeof position === 'string' && position !== 'bottom',
            },
            popoverClassName,
          )}
          data-state={isDropdownOpen ? 'show' : 'hide'}
        >
          {children}
        </div>
      )}
    </div>
  );
};
