import React, { isValidElement, cloneElement, Children, useMemo, useRef, useEffect, useState, ReactNode } from 'react';
import { Text } from './Text';
import classNames from 'classnames';

interface FormItemProps {
  label: string;
  info?: ReactNode;
  desc?: string;
  error?: string;
  children: ReactNode;
  isRequired?: boolean;
  variant?: 'grid' | 'inline' | 'vertical';
  className?: string;
}

interface ElementWithIdProps {
  id?: string;
  [key: string]: unknown;
}

// Custom type to allow ref in cloned elements
type ValidChildElement = React.ReactElement<ElementWithIdProps>;

export const FormItem: React.FC<FormItemProps> = ({ label, desc, info, error, children, isRequired, className }) => {
  // aria-describedby 생성
  const errorId = useMemo(() => `form-item-error-${Math.random().toString(36).substring(2, 9)}`, []);

  // 자식 요소 ID 저장 및 refs 생성
  const idsRef = useRef<Record<number, string>>({});
  const refs = useRef<Array<HTMLElement | null>>([]);
  const [childrenWithIds, setChildrenWithIds] = useState<ValidChildElement[]>([]);

  // label 클릭 시 포커스 가능한 엘리먼트로 포커스 이동
  const childrenWithIdsRef = useMemo(
    () =>
      Children.map(children, (child, index) => {
        if (isValidElement<ElementWithIdProps>(child)) {
          // 이미 ID가 생성되었으면 그대로 사용, 아니면 새로 생성
          if (index === 0 && !idsRef.current[index]) {
            idsRef.current[index] =
              child.props.id ?? `form-item-${index}-${Math.random().toString(36).substring(2, 9)}`;
          }
          const id = idsRef.current[index];

          // ref 설정: HTML 요소나 클래스 컴포넌트인 경우에만 ref를 설정
          const isDOMElement = typeof child.type === 'string';
          const ref = isDOMElement
            ? undefined
            : (node: HTMLElement | null) => {
                refs.current[index] = node;
                // 전달된 ref가 있을 경우에도 연결
                // 타입 오류로 주석처리...
                // if (typeof child.ref === 'function') {
                //   child.ref(node);
                // } else if (child.ref && typeof child.ref === 'object') {
                //   (child.ref as React.MutableRefObject<HTMLElement | null>).current = node;
                // }
              };

          return cloneElement(child, { id, ref } as Partial<ValidChildElement>);
        }
        return child;
      }) as ValidChildElement[],
    [children],
  );

  // 렌더링 후 클래스 이름 검사 및 invalid 속성 설정
  useEffect(() => {
    const invalidTypes = [
      'dsx-Radio',
      'dsx-RadioGroup',
      'dsx-Checkbox',
      'dsx-CheckboxGroup',
      'dsx-Input',
      'dsx-Select',
      'dsx-Textarea',
    ];

    const updatedChildren = childrenWithIdsRef.map((child, index) => {
      if (isValidElement<ElementWithIdProps>(child) && refs.current[index]) {
        const className = refs.current[index]?.className;
        const classNames = className ? className.split(' ') : [];
        const isInvalidType = invalidTypes.some((type) => classNames.includes(type));

        if (error && isInvalidType) {
          return cloneElement(child, { invalid: true } as Partial<ValidChildElement>);
        }
      }
      return child;
    });

    setChildrenWithIds(updatedChildren);
  }, [error, childrenWithIdsRef]);

  return (
    <div className={classNames('dsx-Form-item', className)}>
      <div className="dsx-Form-header">
        <label
          className={classNames('dsx-Label', isRequired && 'is-required')}
          htmlFor={childrenWithIds[0]?.props.id}
          aria-describedby={error ? errorId : undefined}
        >
          {label}
        </label>
        {info && <>{info}</>}
        {desc && <Text accent="alternative">{desc}</Text>}
      </div>

      <div className="dsx-Form-content">
        {childrenWithIds}
        {error && (
          <Text variant="error" id={errorId}>
            {error}
          </Text>
        )}
      </div>
    </div>
  );
};
