import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Loading } from '../../components/views/Loading';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { usePlugin } from '../../libs/hooks/usePlugin';
import { usePrompt } from '../../libs/hooks/usePrompt';
import { AlertType } from '../../libs/models/AlertType';
import { useAppDispatch } from '../../redux/app/hooks';
import { showAlertToast } from '../../redux/features/app/appSlice';
import PluginPromptGnb from './PluginPromptGnb';
import '../../assets/styles/global.scss';
import '../../assets/styles/layout.scss';
import '../../assets/styles/studio.scss';
import { resetCategoryList } from '../../redux/features/studio/categorySlice';
import { useChat } from '../../libs/hooks/useChat';

enum AppState {
  Loading,
  Loaded,
}

const PluginPromptWindow = () => {
  const plugin = usePlugin();
  const chat = useChat();
  const prompt = usePrompt();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [menuTypeState, setMenuTypeState] = useState<'plugin' | 'prompt'>('plugin');
  const [tokenFlag, setTokenFlag] = useState(false);
  const [appState, setAppState] = useState(AppState.Loading);

  /**
   * [Func] 토큰 체크 함수
   */
  const checkToken = useCallback(async () => {
    try {
      const token = await AuthHelper.getToken();
      if (token) {
        setTokenFlag(true);
      }
    } catch (e) {
      dispatch(
        showAlertToast({
          altMessage: String('채팅방에 권한이 없어요. 다른 채팅방을 선택해주세요.'),
          altType: AlertType.Error,
        }),
      );
    }
  }, [dispatch]);

  /**
   * [Func] 플러그인  카테고리 조회
   */
  const callPluginCategory = useCallback(async () => {
    try {
      await plugin.getPluginCategory();
      setAppState(AppState.Loaded);
    } catch (error) {
      console.error('Error occurred in callCategory:', error);
    }
  }, [plugin]);

  /**
   * [Func] 프롬프트 카테고리 조회
   */
  const callPromptCategory = useCallback(async () => {
    try {
      await prompt.getPromptCategory();
      setAppState(AppState.Loaded);
    } catch (error) {
      console.error('Error occurred in callCategory:', error);
    }
  }, [prompt]);

  /**
   * [Func] AI models 조회
   */
  const callAiModels = async () => {
    try {
      await chat.getAllModel();
      setAppState(AppState.Loaded);
    } catch (error) {
      console.error('Error occurred in callCategory:', error);
    }
  };

  useEffect(() => {
    /* url로 plugn, template 구분 */
    const path = location.pathname.split('/')[2];
    const menuType = path === 'plugin' ? 'plugin' : 'prompt';
    setMenuTypeState(menuType);
  }, [location]);

  useEffect(() => {
    void checkToken();
  }, [checkToken]);

  useEffect(() => {
    // 카테고리 목록 초기화
    dispatch(resetCategoryList());
    if (tokenFlag) {
      void callAiModels();
      if (menuTypeState === 'plugin') {
        void callPluginCategory();
      } else {
        void callPromptCategory();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFlag, menuTypeState]);

  return (
    <div className="wrapper studio-wrapper">
      {appState === AppState.Loading && <Loading text="로딩중입니다. 잠시만 기다려주세요." />}
      {appState === AppState.Loaded && (
        <>
          {/* GNB - pluginCreate 일때는 제외 */}
          {location.pathname !== '/makers/plugin/pluginCreate' &&
            location.pathname !== '/makers/prompt/promptCreate' && <PluginPromptGnb />}
          {/* Body */}
          <Outlet />
        </>
      )}
    </div>
  );
};

export default PluginPromptWindow;
