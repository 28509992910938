import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Button } from './Button';
import { FC } from 'react';
import { useAppSelector } from '../redux/app/hooks';
import { useChat } from '../libs/hooks/useChat';

interface IDeleteModalnProps {
  chatId: string;
  onClick: (id: boolean) => void;
}

export const DeleteModal: FC<IDeleteModalnProps> = ({ chatId, onClick }) => {
  const chat = useChat();
  const { conversations } = useAppSelector((state) => state.conversations);

  const onDeleteChat = (chatId: string) => {
    void chat.deleteChat(conversations[chatId]);
  };

  const modalRoot = document.getElementById('modal-root');
  if (!modalRoot) {
    throw new Error('Modal root element not found');
  }

  return createPortal(
    <div className={classNames('ds-modal-overlay is-show')}>
      <div className="ds-modal ds-modal-alert" role="alertdialog">
        <div className="alert-content">
          <h2 className="alert-title">대화 내용 삭제</h2>
          <p className="alert-message">
            삭제된 채팅은 복구할 수 없습니다. <br />
            정말 삭제하시겠습니까?
          </p>
        </div>
        <div className="alert-footer">
          <Button
            theme={'outline'}
            onClick={() => {
              onClick(false);
            }}
          >
            취소
          </Button>
          <Button
            theme={'primary'}
            onClick={() => {
              onClick(false);
              onDeleteChat(chatId);
            }}
          >
            삭제
          </Button>
        </div>
      </div>
    </div>,
    modalRoot,
  );
};
