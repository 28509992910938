import { ReactNode, forwardRef } from 'react';

import classNames from 'classnames';
import { Icon } from './Icon';

interface IButtonProps {
  className?: string;
  classOnly?: string;
  control?: boolean;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: () => void;
  children?: ReactNode;
  icon?: string;
  textBlind?: boolean;
  suffix?: ReactNode;
  theme?: string;
  disabled?: boolean;
  role?: string;
  size?: 'small' | 'large';
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      className = '',
      classOnly,
      control = false,
      type = 'button',
      onClick = () => {},
      children,
      icon,
      textBlind,
      suffix,
      theme,
      disabled,
      role,
      size,
      ...props
    },
    ref,
  ) => {
    const buttonClassName = classNames(
      {
        'ds-control': control,
        'ds-button': !control,
        [`ds-button-${theme}`]: theme && !control,
        [`ds-button--${size}`]: size && !control,
      },
      className,
    );

    return (
      <button
        ref={ref}
        className={classOnly ?? buttonClassName}
        type={type}
        onClick={onClick}
        disabled={disabled}
        role={role}
        {...props}
      >
        {icon && <Icon name={icon} />}
        {suffix}
        <span className={textBlind ? 'blind' : ''}>{children}</span>
      </button>
    );
  },
);
Button.displayName = 'Button';
