import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import PromptCreateDtl from './PromptCreateDtl';
import PromptCreateProfile from './PromptCreateProfile';
import PromptCreateReg from './PromptCreateReg';

const steps = ['프로필 생성', '상세 설정', '등록'];

interface PromptCreateFormProps {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onSubmit: () => void;
  setTestResult: (value: number | ((prev: number) => number)) => void;
  handleSave: (arg0: string) => void;
  testResult: number;
}

const PromptCreateForm: FC<PromptCreateFormProps> = ({
  formData,
  setFormData,
  onSubmit,
  setTestResult,
  testResult,
  handleSave,
}) => {
  const [step, setStep] = useState<number>(formData.createdStep ?? 1);

  const [isTabSelectable, setIsTabSelectable] = useState(false);

  const tabSelectableValue =
    formData.name &&
    formData.profileUrl &&
    formData.description &&
    formData.promptCategory &&
    formData.promptTemplate &&
    formData.model &&
    formData.promptTemplateResponse;

  useEffect(() => {
    if (tabSelectableValue ?? step > 2) {
      setIsTabSelectable(true);
    }
  }, [step, formData]);

  const updateFormDataWithStep = (newStep: number) => {
    setStep(newStep);
    if (formData.promptCategoryId && formData.name && formData.description) {
      setFormData((prev) => ({
        ...prev,
        createdStep: newStep,
      }));
      handleSave('temp'); // Save as temporary
    }
  };

  const handleNext = () => {
    updateFormDataWithStep(step + 1);
  };

  const handlePrev = () => {
    if (step > 1) {
      updateFormDataWithStep(step - 1);
    }
  };

  const handleTest = () => {
    if (testResult >= 10) {
      return;
    }
    setTestResult((prev) => prev + 1);
  };

  const handleTabSelect = (index: number) => {
    if (isTabSelectable) {
      setStep(index + 1);
      setFormData((prev) => ({ ...prev, createdStep: index + 1 }));
      handleSave('temp'); // Save as temporary
    }
  };

  useEffect(() => {
    if (formData.createdStep && formData.createdStep !== step) {
      setStep(formData.createdStep);
    }
  }, [formData.createdStep]);

  return (
    <>
      <div className="form-step">
        <ol className="step-list" role="img" aria-label={`총 ${steps.length}단계 중 ${step}단계 ${steps[step - 1]}`}>
          {steps.map((stepName, index) => (
            <li
              key={index}
              className={classNames(
                index + 1 < step ? 'is-complete' : '',
                index + 1 === step ? 'is-active' : '',
                isTabSelectable ? 'selectable' : '',
              )}
              onClick={() => {
                handleTabSelect(index);
              }}
            >
              <span className="step-name">{stepName}</span>
            </li>
          ))}
        </ol>
      </div>
      <div className={classNames('form-panel')}>
        {step === 1 && <PromptCreateProfile formData={formData} setFormData={setFormData} onNext={handleNext} />}
        {step === 2 && (
          <PromptCreateDtl
            formData={formData}
            setFormData={setFormData}
            onPrev={handlePrev}
            onTest={handleTest}
            onNext={handleNext}
          />
        )}
        {step === 3 && (
          <PromptCreateReg formData={formData} setFormData={setFormData} onPrev={handlePrev} onSubmit={onSubmit} />
        )}
      </div>
    </>
  );
};

export default PromptCreateForm;
