import { FC, useCallback, useEffect, useState } from 'react';
import { ChatWindow } from '../../pages/chat/ChatWindow';
import { ChatAside } from '../../pages/chat/chat-list/ChatAside';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useChat } from '../../libs/hooks/useChat';
import { showAlertToast } from '../../redux/features/app/appSlice';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { AlertType } from '../../libs/models/AlertType';
import { checkMobileKate } from '../utils/common';
import { ChatHeaderOnMobile } from '../../pages/chat/ChatHeaderOnMobile';
import { Constants } from '../../Constants';

export const ChatView: FC = () => {
  const [isDesktop, setIsDesktop] = useState(!checkMobileKate() || window.innerWidth <= 768);

  const { selectedId } = useAppSelector((state: RootState) => state.conversations);
  const { chatRoomId } = useParams();

  const dispatch = useDispatch();
  const chat = useChat();

  useEffect(() => {
    if (chatRoomId && selectedId != chatRoomId) {
      chat.changeChatRoom(chatRoomId).catch(() => {
        dispatch(
          showAlertToast({
            altMessage: String('채팅방에 권한이 없어요. 다른 채팅방을 선택해주세요.'),
            altType: AlertType.Error,
          }),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomId]);

  useEffect(() => {
    setIsDesktop(window.innerWidth > 768);
  }, []);

  // collapsed 규칙 :
  // 마우스로 누른게 더 우선이다.
  // 마우스로 누르지 않은 경우에만 자동 여닫기 지원한다.

  const [isAsideCollapsed, setIsAsideCollapsed] = useState(window.innerWidth + 428 <= 1200); // 428 : 포털 widget-area 영역
  const [isAsideClicked, setIsAsideClicked] = useState(false);

  const toggleAside = () => {
    setIsAsideClicked(true);
    setIsAsideCollapsed((prev) => !prev);
  };

  const handleResize = useCallback(() => {
    if (!isAsideClicked) {
      setIsAsideCollapsed(window.innerWidth + 428 <= 1200);
    }
  }, [isAsideClicked]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  // 디바운스를 사용한 커스텀 훅 호출
  useDebouncedEffect(handleResize, Constants.KEYSTROKE_DEBOUNCE_TIME_MS, [isAsideClicked]);

  return (
    <div className={`chat-view `}>
      {!isDesktop && <ChatHeaderOnMobile toggleCollapse={toggleAside} />}
      <ChatAside isAsideCollapsed={isAsideCollapsed} toggleAside={toggleAside} />
      <ChatWindow />
    </div>
  );
};

const useDebouncedEffect = (effect: () => void, delay: number, deps: boolean[]) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      effect();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [effect, delay, deps]);
};
