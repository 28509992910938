import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';

export interface PluginStudioState {
  pluginStudio: IPluginPromptData;
}

const initialState: PluginStudioState = {
  pluginStudio: {},
};

export const pluginStudioSlice = createSlice({
  name: 'pluginStudio',
  initialState,
  reducers: {
    setPluginStudioModel: (state: PluginStudioState, action: PayloadAction<IPluginPromptData>) => {
      state.pluginStudio = { ...action.payload };
    },
    resetPluginStudioModel(state) {
      state.pluginStudio = {}; // 초기화할 값으로 설정
    },
  },
});

export const { setPluginStudioModel, resetPluginStudioModel } = pluginStudioSlice.actions;

export default pluginStudioSlice.reducer;
