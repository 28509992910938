import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface StackProps {
  vertical?: boolean;
  inline?: boolean;
  children: ReactNode;
  className?: string;
  [key: string]: any;
}

export const Stack: React.FC<StackProps> = ({ vertical, inline, children, className, ...props }) => {
  return (
    <div 
      className={classNames('dsx-Stack', inline && 'dsx-inline-flex', vertical && 'dsx-align-vertical' , className)}
      {...props}
    >
      {children}
    </div>
  );
};
