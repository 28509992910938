import React, { useEffect, useState, useRef, ChangeEvent } from 'react';
import Icon from './Icon';
import classNames from 'classnames';

// Chip 컴포넌트의 Props 타입 정의
// extends React.InputHTMLAttributes<HTMLInputElement>
interface ChipProps {
  id?: string;
  checked?: boolean;
  variant?: 'outlined' | 'filled';
  label?: React.ReactNode;
  prefixIcon?: string;
  suffixIcon?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  type?: 'checkbox' | 'radio';
  name?: string;
  className?: string;
}

export const Chip: React.FC<ChipProps> = ({
  id,
  checked,
  variant,
  label,
  prefixIcon,
  suffixIcon,
  onChange,
  disabled,
  type = 'checkbox',
  name,
  className,
  ...props
}) => {
  // 유일한 chip ID 생성
  const chipId = useRef(id ?? `chip-${Math.random().toString(36).substring(2, 6)}`).current;

  // 체크 상태 관리
  const [check, setCheck] = useState(checked ?? false);

  // 체크 상태 업데이트
  useEffect(() => {
    if (checked !== undefined) setCheck(checked);
  }, [checked]);

  // 체크박스 상태 변경 처리
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (onChange) onChange(event, isChecked);
    if (type === 'checkbox') {
      setCheck(isChecked);
    }
  };

  return (
    <div className={classNames('dsx-Chip', variant && `dsx-Chip--${variant}`, className)}>
      <input
        type={type}
        className="dsx-Chip-input"
        id={chipId}
        checked={check}
        onChange={handleOnChange}
        disabled={disabled}
        name={name}
        {...props}
      />
      <label htmlFor={chipId} className="dsx-Chip-label">
        {prefixIcon && <Icon name={prefixIcon} />}
        {label}
        {suffixIcon && <Icon name={suffixIcon} />}
      </label>
    </div>
  );
};
