import React, { createContext, useContext, ReactNode, FormEvent } from 'react';
import classNames from 'classnames';

// Define the context with default value 'inline'
const FormContext = createContext<'inline' | 'grid' | 'vertical'>('inline');

// Custom hook to use the FormContext
export const useForm = () => useContext(FormContext);

// Define the props type for the Form component
interface FormProps {
  children: ReactNode;
  onSubmit: (e: React.FormEvent) => void;
  variant?: 'grid' | 'inline' | 'vertical';
  column?: number;
  className: string;
}

// Form component
export const Form: React.FC<FormProps> = ({ children, onSubmit, variant = 'inline', column = 1, className }) => {
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <FormContext.Provider value={variant}>
      <form
        onSubmit={handleSubmit}
        className={classNames('dsx-Form', `dsx-Form--${variant}`, column > 1 && `dsx-column-${column}`, className)}
      >
        {children}
      </form>
    </FormContext.Provider>
  );
};
