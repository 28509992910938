// 파일 확장자 추출
export const getFileExtension = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) return ''; // '.'이 없는 경우
  return fileName.substring(lastDotIndex + 1).toLowerCase();
};

// 파일 확장자에 따른 이미지 파일 이름 매핑
export const getFileIcon = (fileName: string) => {
  const extension = getFileExtension(fileName);
  switch (extension) {
    case 'pdf':
      return 'etc-icons/ico_pdf.svg';
    case 'xlsx':
    case 'xls':
    case 'xlsm':
    case 'xlsb':
    case 'xml':
      return 'etc-icons/ico_xlxs.svg';
    case 'doc':
    case 'docx':
    case 'dot':
    case 'dotx':
      return 'etc-icons/ico_doc.svg';
    default:
      return 'etc-icons/ico_etc.svg';
  }
};

// 이미지 파일 확장자인지 확인
export const isImageFile = (fileName: string) => {
  const ext = getFileExtension(fileName);
  return ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif' || ext === 'svg';
};

export const formatFileSize = (size: number) => {
  if (size < 1024) {
    return size + 'B';
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(1) + 'KB';
  } else if (size < 1024 * 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(1) + 'MB';
  } else {
    return (size / (1024 * 1024 * 1024)).toFixed(1) + 'GB';
  }
};

export const bytesToSize = (bytes: number, decimal = 1) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'N/A';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(decimal)} ${sizes[i]}`;
};
