import React from 'react';
import { IChatVisual } from '../../libs/models/users/ChatVisual';
import { Link } from '../../components/Link';

interface ChatInsightProps {
  data: IChatVisual;
}
export const ChatVisual: React.FC<ChatInsightProps> = ({ data }) => {
  return (
    <Link className={data.link ? '' : 'no-click'} url={data.link as string} target="_blank" rel="noopener noreferrer">
      <div className="chat-visual">
        <div className="visual">
          <video src={data.icon} muted autoPlay loop playsInline disablePictureInPicture />
        </div>
        <h2 className="title">{data.title}</h2>
        <p className="desc">{data.description}</p>
      </div>
    </Link>
  );
};
