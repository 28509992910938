import classNames from 'classnames';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import Icon from './Icon';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  size?: 'small' | 'medium' | 'large';
  children: ReactNode;
  className?: string;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  { name, size, children, className, ...props },
  ref,
) {
  return (
    <button
      ref={ref}
      type="button"
      className={classNames('dsx-IconButton', size && `dsx-IconButton--${size}`, className)}
      {...props}
    >
      <Icon name={name} />
      <span className="dsx-blind">{children}</span>
    </button>
  );
});

IconButton.displayName = 'IconButton';
