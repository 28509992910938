import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { IconButton } from './IconButton';
import { Heading } from './Heading';
import { Text } from './Text';
import { createPortal } from 'react-dom';
import AnimationState from './AnimationState';
import FocusTrap from './FocusTrap';
import classNames from 'classnames';

const validSizes = ['default', 'small', 'large', 'full'];

interface DialogProps {
  isOpen: boolean;
  title?: string;
  subTitle?: string;
  children?: ReactNode;
  size?: 'default' | 'small' | 'large' | 'full';
  onClose?: () => void;
  closeOnOutSide?: boolean;
  buttons?: ReactNode;
  className?: string;
}

export const Dialog: React.FC<DialogProps> = ({
  isOpen,
  title,
  subTitle,
  children = '',
  size = 'default',
  onClose,
  closeOnOutSide = false,
  buttons,
  className,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  // [SSR 오류] 클라이언트 사이드에서 생성하도록 변경
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const root =
        document.getElementById('portal-root') ??
        (() => {
          const div = document.createElement('div');
          div.id = 'portal-root';
          document.body.appendChild(div);
          return div;
        })();
      setPortalRoot(root);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
        handleClose();
      }
    };

    if (isOpen && closeOnOutSide) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, closeOnOutSide]);

  const handleClose = () => {
    onClose?.();
  };

  if (!portalRoot) return null; // SSR 시 오류발생 방지용

  return createPortal(
    <AnimationState isActive={isOpen} nodeRef={overlayRef}>
      <FocusTrap isActive={isOpen}>
        <div className="dsx-ModalOverlay" ref={overlayRef}>
          <div
            role="dialog"
            // className={`dsx-Modal dsx-Dialog ${size === 'large' ? 'dsx-Dialog--large' : size === 'small' ? 'dsx-Dialog--small' : ''}`}
            className={classNames(
              'dsx-Modal dsx-Dialog',
              { [`dsx-Dialog--${size}`]: validSizes.includes(size) && size !== 'default' },
              className,
            )}
            ref={dialogRef}
          >
            {title && (
              <div className="dsx-Dialog-header">
                <Heading as="h2" size="title1" weight="semibold">
                  {title}
                </Heading>
                <IconButton name="closeLarge" className="dsx-Dialog-close" onClick={handleClose}>
                  닫기
                </IconButton>
                {subTitle && <Text size="body2">{subTitle}</Text>}
              </div>
            )}
            {(title ?? buttons) ? (
              <div className="dsx-Dialog-content" tabIndex={0}>
                {children}
              </div>
            ) : (
              children
            )}
            {buttons && <div className="dsx-Dialog-footer">{buttons}</div>}
          </div>
        </div>
      </FocusTrap>
    </AnimationState>,
    portalRoot,
  );
};
