import React from 'react';
import classNames from 'classnames';

// Text 컴포넌트의 Props 타입 정의
// extends React.HTMLProps<HTMLElement>
interface TextProps {
  as?: 'p' | 'span' | 'strong' | 'label' | 'li' | 'pre';
  variant?: 'default' | 'primary' | 'secondary' | 'error' | 'length'; // variant 값에 맞게 조정 필요
  size?: 'body1' | 'body2' | 'body3' | 'label1' | 'label2' | 'caption1' | 'caption2';
  weight?: 'bold' | 'semibold' | 'medium' | 'regular';
  accent?: 'neutral' | 'alternative'; // accent 값에 맞게 조정 필요
  children: React.ReactNode;
  className?: string;
  id?: string;
}

export const Text: React.FC<TextProps> = ({
  as = 'p',
  variant,
  size,
  weight,
  accent,
  children,
  className,
  id,
  ...props
}) => {
  // `as` 속성에 해당하는 HTML 태그를 동적으로 생성합니다.
  const Tag = as as keyof JSX.IntrinsicElements;

  return (
    <Tag
      id={id}
      className={classNames(
        'dsx-Text',
        variant && `dsx-Text--${variant}`,
        size && `dsx-Text--${size}`,
        weight && `dsx-weight-${weight}`,
        accent && `dsx-color-${accent}`,
        className,
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default Text;
