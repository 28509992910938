import React, { memo } from 'react';

interface IconUploadProps {
  variant?: 'primary' | 'normal';
  size?: number;
}

export const IconUpload: React.FC<IconUploadProps> = memo(({ variant = 'primary', size = 52 }) => {
  const colors = {
    primary: {
      fill1: '#1A75FF',
      fill2: '#4891FF',
      stroke: 'white',
    },
    normal: {
      fill1: '#70737c',
      fill2: '#878a93',
      stroke: '#DCDCDC',
    },
  };

  const selectedColors = colors[variant];

  return (
    <svg width={size} height={size} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.3332 15.4596V44.168C43.3332 45.3393 42.8767 46.4627 42.0639 47.291C41.2512 48.1193 40.149 48.5846 38.9998 48.5846H12.9998C11.8506 48.5846 10.7484 48.1193 9.93569 47.291C9.12304 46.4627 8.6665 45.3393 8.6665 44.168V8.83464C8.6665 7.66327 9.12304 6.5398 9.93569 5.7115C10.7484 4.88322 11.8506 4.41797 12.9998 4.41797H32.4998L43.3332 15.4596Z"
        fill={selectedColors.fill1}
      />
      <path
        d="M32.5 31L26.5 25M26.5 25L20.5 31M26.5 25L26.5 37.5"
        stroke={selectedColors.stroke}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M34.6667 15.4596H43.3333L32.5 4.41797V13.2513C32.5 13.837 32.7284 14.3987 33.1346 14.8128C33.5409 15.2269 34.0921 15.4596 34.6667 15.4596Z"
        fill={selectedColors.fill2}
      />
    </svg>
  );
});

IconUpload.displayName = 'IconUpload';
