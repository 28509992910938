import { useEffect, useRef, useState, ReactNode } from 'react';
import { Heading } from './Heading';
import { Text } from './Text';
import { Button } from './Button';
import { createPortal } from 'react-dom';
import FocusTrap from './FocusTrap';
import AnimationState from './AnimationState';

interface AlertDialogProps {
  title: string;
  message: ReactNode;
  confirmLabel?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

export const AlertDialog: React.FC<AlertDialogProps> = ({
  title,
  message,
  confirmLabel = '확인',
  onConfirm,
  onCancel,
}) => {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setIsVisible(true);
    if (typeof document !== 'undefined') {
      const root =
        document.getElementById('portal-root') ??
        (() => {
          const div = document.createElement('div');
          div.id = 'portal-root';
          document.body.appendChild(div);
          return div;
        })();
      setPortalRoot(root);
    }
    if (document.activeElement instanceof HTMLElement) {
      triggerRef.current = document.activeElement;
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  const handleClose = (callback?: () => void) => {
    if (callback) {
      callback();
    }
    setIsVisible(false);
    if (overlayRef.current) {
      overlayRef.current.addEventListener(
        'animationend',
        () => {
          if (triggerRef.current) {
            triggerRef.current.focus();
          }
        },
        { once: true },
      );
    }
  };

  if (!portalRoot) return null;

  return createPortal(
    <AnimationState isActive={isVisible} nodeRef={overlayRef}>
      <FocusTrap isActive={isVisible}>
        <div ref={overlayRef} className="dsx-ModalOverlay">
          <div role="alertdialog" className="dsx-Modal dsx-AlertDialog">
            <div className="dsx-AlertDialog-content">
              <Heading as="h2" size="body1" weight="semibold">
                {title}
              </Heading>
              {typeof message === 'string' ? <Text size="body2">{message}</Text> : message}
            </div>
            <div className="dsx-AlertDialog-footer">
              {onCancel && (
                <Button
                  variant="outline"
                  size="large"
                  onClick={() => {
                    handleClose(onCancel);
                  }}
                >
                  취소
                </Button>
              )}
              <Button
                variant="primary"
                size="large"
                onClick={() => {
                  handleClose(onConfirm);
                }}
              >
                {confirmLabel}
              </Button>
            </div>
          </div>
        </div>
      </FocusTrap>
    </AnimationState>,
    portalRoot,
  );
};
