import React, { useState, useEffect } from 'react';
import {
  Badge,
  IconButton,
  Textarea,
  ButtonArea,
  Button,
  Text,
  Heading,
  Checkbox,
  Pagination,
} from '../../../components/base';
import { IinputOutput, IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import { usePlugin } from '../../../libs/hooks/usePlugin';
import Icon from '../../../components/base/Icon';

interface IinputOutputWithState extends IinputOutput {
  status: string;
  isExpanded: boolean;
  isEditing: boolean;
  isSelected: boolean;
}

interface IPluginLearningData {
  ragContentsData?: IinputOutput[] | null;
  setRagContentsData: React.Dispatch<React.SetStateAction<IinputOutput[]>>;
  formData: IPluginPromptData;
}

const PluginLearningData: React.FC<IPluginLearningData> = ({ ragContentsData, setRagContentsData, formData }) => {
  const plugin = usePlugin();
  const [regContentItems, setRegContentItems] = useState<IinputOutputWithState[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [editingInput, setEditingInput] = useState('');
  const [editingOutput, setEditingOutput] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [pagenation, setPagenation] = useState(1);
  const itemsPerPage = 10;

  const getBadgeAccent = (item: IinputOutputWithState) => {
    if (item.status === 'DeleteFailed') return 'negative';
    if (item.status === 'Deleting') return 'caution';
    if (item.isEditing) return 'violet';
    if (item.status === 'learning') return 'caution';
    return item.hasError ? 'negative' : 'info';
  };
  const getBadgeMessage = (item: IinputOutputWithState) => {
    if (item.status === 'DeleteFailed') return '삭제 실패';
    if (item.status === 'Deleting') return '삭제중';
    if (item.isEditing) return '수정중';
    if (item.status === 'learning') return '학습중';
    return item.hasError ? '학습 실패' : '학습 성공';
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setRegContentItems(regContentItems.map((item) => ({ ...item, isSelected: newSelectAll })));
  };

  const handleItemSelect = (id: string) => {
    setRegContentItems(
      regContentItems.map((item) => (item.id === id ? { ...item, isSelected: !item.isSelected } : item)),
    );
  };

  const handleExpand = (id: string) => {
    setRegContentItems(
      regContentItems.map((item) => (item.id === id ? { ...item, isExpanded: !item.isExpanded } : item)),
    );
  };
  const handleEdit = (id: string) => {
    const itemToEdit = regContentItems.find((item) => item.id === id);
    setEditingInput(itemToEdit?.inPut ?? '');
    setEditingOutput(itemToEdit?.outPut ?? '');
    setRegContentItems(
      regContentItems.map((item) => (item.id === id ? { ...item, isEditing: true, isExpanded: true } : item)),
    );
  };

  const handleCancelEdit = (id: string) => {
    setRegContentItems(regContentItems.map((item) => (item.id === id ? { ...item, isEditing: false } : item)));
    setEditingInput('');
    setEditingOutput('');
  };

  const handleSaveEdit = async (id: string) => {
    setRegContentItems(
      regContentItems.map((item) =>
        item.id === id ? { ...item, inPut: editingInput, outPut: editingOutput, isEditing: false } : item,
      ),
    );

    const obj = {
      id: id,
      inPut: editingInput,
      outPut: editingOutput,
      customPluginId: formData.id as string,
    };
    try {
      const res = await plugin.putRagContent(obj);
      setRagContentsData((prevData) => prevData.map((item) => (item.id === id ? { ...item, ...res.value } : item)));
    } catch (e) {
      console.log(e);
    }

    setEditingInput('');
    setEditingOutput('');
  };

  /**
   * [Func] 단건으로 삭제
   * @returns
   */
  const handleDelete = async (selecteditem: IinputOutput) => {
    setRagContentsData((prevData) =>
      prevData.map((item) => (item.id === selecteditem.id ? { ...item, status: 'Deleting' } : item)),
    );
    // 플러그인 API 호출
    const obj = {
      id: selecteditem.id,
      partitionKey: selecteditem.partitionKey,
      updatedTime: selecteditem.updatedTime,
      createdTime: selecteditem.createdTime,
      deleted: selecteditem.deleted,
      companyCode: selecteditem.companyCode,
      inPut: selecteditem.inPut,
      outPut: selecteditem.outPut,
      customPluginId: formData.id,
    };
    try {
      const result = await plugin.deleteRagContent(obj);
      if (result.statusCode == 200) {
        const updatedRagContents = regContentItems.filter((item) => item.id !== selecteditem.id);
        setRegContentItems(updatedRagContents);
        // ragContentsData에서도 해당 항목 삭제
        setRagContentsData((prevData) => prevData.filter((item) => item.id !== selecteditem.id));
      } else {
        setRagContentsData((prevData) =>
          prevData.map((item) => (item.id === selecteditem.id ? { ...item, status: 'DeleteFailed' } : item)),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * [Func] 체크박스 선택한 항목들 삭제
   * @returns
   */
  const handleSelectedDelete = async () => {
    const selectedItems = regContentItems
      .filter((item) => item.isSelected)
      .map((item) => ({
        id: item.id,
        partitionKey: item.partitionKey,
        updatedTime: item.updatedTime,
        createdTime: item.createdTime,
        deleted: item.deleted,
        companyCode: item.companyCode,
        inPut: item.inPut,
        outPut: item.outPut,
        customPluginId: formData.id,
      }));

    if (selectedItems.length === 0) {
      return;
    }

    try {
      await plugin.deleteRagContents(selectedItems);
      setRegContentItems((prevItems) => prevItems.filter((item) => !item.isSelected));
      setRagContentsData((prevData) => prevData.filter((item) => !selectedItems.some((sel) => sel.id === item.id)));
      setSelectAll(false); // 전체 선택 해제
    } catch (e) {
      console.log(e);
    }
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    if (isEditMode) {
      // 편집 모드를 종료할 때 모든 선택 해제
      setSelectAll(false);
      setRegContentItems(regContentItems.map((item) => ({ ...item, isSelected: false })));
    }
  };

  useEffect(() => {
    if (ragContentsData) {
      let startIndex = (pagenation - 1) * itemsPerPage;

      if (ragContentsData && ragContentsData.length <= (pagenation - 1) * itemsPerPage) {
        startIndex = (pagenation - 2) * itemsPerPage;
      }
      // 페이지 시작 및 끝 인덱스 계산
      const endIndex = startIndex + itemsPerPage;

      // 현재 페이지에 해당하는 데이터를 IinputOutputWithState 형식으로 변환
      const updatedItems = ragContentsData.slice(startIndex, endIndex).map((item) => ({
        ...item,
        isExpanded: false,
        isEditing: false,
        isSelected: false,
      })) as IinputOutputWithState[];
      setRegContentItems(updatedItems);

      // // 페이지에 남은 항목이 적을 경우, 다음 페이지의 데이터를 가져와 채우기
      // if (updatedItems.length < itemsPerPage && pagenation < Math.ceil(ragContentsData.length / itemsPerPage)) {
      //   const nextPageItems = ragContentsData
      //     .slice(endIndex, endIndex + (itemsPerPage - updatedItems.length))
      //     .map((item) => ({
      //       ...item,
      //       isExpanded: false,
      //       isEditing: false,
      //       isSelected: false,
      //     })) as IinputOutputWithState[];

      //   // 두 배열을 합쳐서 설정
      //   setRegContentItems([...updatedItems, ...nextPageItems]);
      // } else {
      //   setRegContentItems(updatedItems);
      // }
    }
  }, [ragContentsData, pagenation, itemsPerPage]);

  useEffect(() => {
    const allSelected = regContentItems.every((item) => item.isSelected);
    setSelectAll(allSelected);
  }, [regContentItems]);

  return (
    <>
      <div className="dsx-title-bar">
        <Heading size="body3" weight="semibold">
          등록 데이터
        </Heading>
        <Badge size="large" accent="violet">
          {ragContentsData?.length as number}
        </Badge>
        <div className="dsx-side-right">
          <Button variant="text" onClick={toggleEditMode}>
            {isEditMode ? '편집 취소' : '목록 편집'}
          </Button>
        </div>
      </div>
      {isEditMode && (
        <div className="learning-edit">
          <Checkbox checked={selectAll} onChange={handleSelectAll}>
            전체선택
          </Checkbox>
          <div className="dsx-side-right">
            <Button
              variant="secondary"
              onClick={() => {
                void handleSelectedDelete();
              }}
            >
              삭제
            </Button>
          </div>
        </div>
      )}
      <div className="learning-list">
        {regContentItems.map((item) => (
          <div key={item.id} className={`learning-item ${item.isExpanded ? 'is-active' : ''}`}>
            <div className="item-head">
              {isEditMode && (
                <Checkbox
                  checked={item.isSelected}
                  onChange={() => {
                    handleItemSelect(item.id as string);
                  }}
                  disabled={item.status === 'learning'}
                  only
                >
                  선택
                </Checkbox>
              )}
              <Badge variant="tint" size="large" round accent={getBadgeAccent(item)}>
                {(item.status === 'learning' || item.status === 'Deleting') && <Icon name="loading" />}
                {getBadgeMessage(item)}
              </Badge>
              {item.status !== 'learning' && (
                <div className="item-edit">
                  <IconButton
                    name="edit"
                    size="large"
                    onClick={() => {
                      handleEdit(item.id as string);
                    }}
                  >
                    수정
                  </IconButton>
                  <IconButton
                    name="delete"
                    size="large"
                    onClick={() => {
                      void handleDelete(item);
                    }}
                  >
                    삭제
                  </IconButton>
                </div>
              )}
              <IconButton
                name="chevronBottom"
                size="large"
                className="item-control"
                aria-expanded={item.isExpanded}
                onClick={() => {
                  handleExpand(item.id as string);
                }}
                disabled={item.status === 'learning' || item.isEditing}
              >
                {item.isExpanded ? '축소' : '확장'}
              </IconButton>
            </div>
            <div className="item-body">
              <div className="item-data">
                {item.isEditing ? (
                  <Textarea
                    size="xlarge"
                    rows={3}
                    maxRows={8}
                    maxLength={2000}
                    showCount
                    value={editingInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      setEditingInput(e.target.value);
                    }}
                  />
                ) : (
                  <Text as="pre">{item.inPut}</Text>
                )}
              </div>
              <div className="item-data">
                {item.isEditing ? (
                  <Textarea
                    size="xlarge"
                    rows={3}
                    maxRows={8}
                    maxLength={2000}
                    showCount
                    value={editingOutput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      setEditingOutput(e.target.value);
                    }}
                  />
                ) : (
                  <Text as="pre">{item.outPut}</Text>
                )}
              </div>
              {item.isEditing && (
                <ButtonArea align="end">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      handleCancelEdit(item.id as string);
                    }}
                  >
                    취소
                  </Button>
                  <Button
                    variant="normal"
                    onClick={() => {
                      void handleSaveEdit(item.id as string);
                    }}
                  >
                    확인
                  </Button>
                </ButtonArea>
              )}
            </div>
          </div>
        ))}
      </div>
      {ragContentsData && ragContentsData.length > 10 && (
        <Pagination
          mode="basic"
          variant="default"
          page={1}
          pageRange={5}
          resultsPerPage={itemsPerPage}
          setPage={(page) => {
            setPagenation(page);
          }}
          totalResults={ragContentsData.length}
        />
      )}
    </>
  );
};

export default PluginLearningData;
