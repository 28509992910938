export interface PluginAPI {
  id: string;
  name: string;
  model: string | null;
  persona: string;
  description: string;
  profileUrl: string;
  isAvailable?: boolean;
  tags?: string[];
  conversationStarters?: string[];
  hidden?: boolean;
}
export interface Plugin {
  pluginInfo: PluginAPI;
  enabled: boolean;
}

export type Plugins = Record<string, PluginAPI>;

export interface PluginsState {
  plugins: Plugins;
  selectedPlugin: PluginAPI | null;
}

export const enum BuiltInPlugins {
  Wendy = 'Plugin:Wendy',
  AiSearch = 'Plugin:AiSearch',
  KtdsAssistant = 'Plugin:KtdsAssistant',
}

export const initialState: PluginsState = {
  plugins: {},
  selectedPlugin: null,
};
