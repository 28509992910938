import { Button } from '../../../components/Button';

interface IMessageNavigationProps {
  currentPage: number;
  totalPages: number;
  onPrevClick?: () => void;
  onNextClick?: () => void;
}

export const MessageNavigation: React.FC<IMessageNavigationProps> = ({
  currentPage,
  totalPages,
  onPrevClick,
  onNextClick,
}) => {
  return (
    <div className="msg-navigation">
      <Button className="ds-control-prev" control textBlind disabled={currentPage === 1} onClick={onPrevClick}>
        이전
      </Button>
      <span className="page">
        {currentPage}/{totalPages}
      </span>
      <Button className="ds-control-next" control textBlind disabled={currentPage === totalPages} onClick={onNextClick}>
        다음
      </Button>
    </div>
  );
};
