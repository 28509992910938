import { UserData } from '../users/UsersState';
import { IPluginPromptCategoryData } from './Category';

export enum EOpenScopeType {
  Public = 'Public',
  Private = 'Private',
}

export enum ECreatedStatusType {
  Registration = 0, // 등록중
  Learning = 1, // 학습중
  LearningComplete = 2, // 학습완료
  Complete = 3, // 완료
}

export enum EVersionStatusType {
  Draft = 0, // 임시저장
  Creat = 1, // 최초
  Update = 2, // 업데이트버전
}

export interface IPluginPromptData {
  pluginCategory?: IPluginPromptCategoryData;
  promptCategory?: IPluginPromptCategoryData;
  ragContentsData?: IinputOutput[] | null;
  ragContentsDataCount?: number | null;
  conversationStarters?: string[];
  conversationStarterCount?: number | null;
  bookmarkUserCount?: number | null;
  companyCode?: string | null;
  pluginCategoryId?: string;
  promptCategoryId?: string;
  name?: string | null;
  description?: string | null;
  model?: string | null;
  profileUrl?: string | null;
  profileUrlVersion?: number | null;
  openScope?: EOpenScopeType;
  persona?: null;
  customPersona?: string;
  ragFileIds?: string[];
  ragFiles?: IFileData[];
  ragContentsDataIds?: string[];
  conversationStarterIds?: string[];
  bookmarkUserIds?: string[];
  enabled?: boolean;
  createdStatus?: ECreatedStatusType | keyof typeof ECreatedStatusType;
  versionStatus?: EVersionStatusType | keyof typeof EVersionStatusType;
  createdStep?: number | null;
  createdMember?: UserData | null;
  deletedTime?: Date | null;
  tags?: string[];
  type?: string | null;
  id?: string | null;
  partitionKey?: string | null;
  updatedTime?: Date | null;
  createdTime?: Date | null;
  deleted?: boolean | null;
  promptTemplate?: string;
  promptTemplateResponse?: string;
  inputOutput?: IinputOutput[] | null;
  message?: string;
  hidden?: boolean;
}

export interface ICreatedMember {
  companyCode?: string | null;
  userId?: string | null;
  fullName?: string | null;
}

export interface IPluginPromptAlertMessage {
  id: string;
  name: string;
  // category: IPluginPromptCategoryData;
  description: string | null;
  likes: number;
  comments: number;
}

export interface IinputOutput {
  companyCode?: string | null;
  createdTime?: string | null;
  deleted?: boolean | null;
  id?: string | null;
  inPut?: string | null;
  outPut?: string | null;
  partitionKey?: string | null;
  type?: string | null;
  updatedTime?: string | null;
  customPluginId?: string | null;
  message?: string | null;
  hasError?: boolean;
  status?: string;
}

export interface IPluginPromptParam {
  categoryId?: string | null;
  openScope?: 0 | 1 | null;
  createdStatus?: 0 | 1 | 2 | 3 | null;
  versionStatus?: 0 | 1 | 2 | null;
  isDeleted?: boolean | null;
  orderBy?: string | null;
  orderDirection?: string | null;
  isBookmark?: boolean | null;
  isMy?: boolean | null;
  keyWord?: string | null;
  count?: number;
  continuationToken?: string | null;
  inputOutput?: IinputOutput[] | null;
  [key: string]: string | number | boolean | null | IinputOutput[] | undefined;
}

export interface IPluginPromptList {
  continuationToken: string | null;
  data: IPluginPromptData[];
  totalCount: number;
}

export interface IPluginPromptSave {
  value: IPluginPromptData;
  statusCode: number;
}

export interface IPluginFileSave {
  value: IFileData;
  statusCode: number;
}

export interface IinputOutputSave {
  value: IinputOutput;
  statusCode: number;
}

export interface IFileData {
  originalDocumentId: string;
  index: string;
  documentId: string;
  fileName: string;
  notDownloadable: boolean;
  isDrm: boolean;
  isDraft: boolean;
  isCompleted: boolean;
  hasError: boolean;
  created: Date;
  deleteRequested: boolean;
  eTag: string;
  companyCode?: string | null;
  partitionKey: string;
  rowKey: string;
  updatedTime?: string | null;
  timestamp: Date;
  fileSizeLong?: number;
  status: 'Published' | 'AddRequsted' | 'EditRequested' | 'DeleteRequsted' | 'Error';
}

export interface IProfileImg {
  value: string;
  statusCode: number;
}
