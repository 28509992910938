import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  ButtonArea,
  Form,
  FormItem,
  Heading,
  Select,
  Text,
  Textarea,
  Tooltip,
} from '../../../components/base';
import { IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';

interface Errors {
  promptTemplate: string;
  model: string;
}

interface IPromptCreateDtl {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onPrev: () => void;
  onTest: () => void;
  onNext: () => void;
}

const PromptCreateDtl: React.FC<IPromptCreateDtl> = ({ formData, setFormData, onPrev, onTest, onNext }) => {
  const [errors, setErrors] = useState<Errors>({
    promptTemplate: '',
    model: '',
  });
  const [testDisabled, setTestDisabled] = useState(true);
  const [testTooltipVisible, setTestTooltipVisible] = useState(false);
  const { models } = useAppSelector((state: RootState) => state.models);

  const modelOptions = Object.keys(models).map((model) => ({
    value: model,
    label: model,
  }));

  useEffect(() => {
    if (formData.promptTemplate && formData.model) {
      setTestDisabled(false);
      setTestTooltipVisible(true);
    } else {
      setTestDisabled(true);
      setTestTooltipVisible(false);
    }
  }, [formData]);

  const validate = () => {
    const newErrors = {
      promptTemplate: '',
      model: '',
    };
    if (!formData.promptTemplate) newErrors.promptTemplate = '프롬프트를 입력해 주세요.';
    if (!formData.model) newErrors.model = '사용 모델을 선택해 주세요.';
    setErrors(newErrors);
    return Object.values(newErrors).every((value) => value.trim().length === 0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? '' : prevErrors[name as keyof Errors],
    }));
  };

  const handleSelectChange = (selectedOption: string | number) => {
    setFormData((prev) => ({ ...prev, model: selectedOption as string }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      model: selectedOption ? '' : prevErrors.model,
    }));
  };

  const handleTest = () => {
    setTestTooltipVisible(false);
    onTest();
  };

  const handlePrev = () => {
    onPrev();
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) onNext();
  };

  return (
    <>
      <Form variant="vertical" className="form-field" onSubmit={handleFormSubmit}>
        <FormItem
          label="프롬프트"
          desc="사용자가 등록할 내용은 [[ ]] 겹괄호로 표시해주세요."
          info={
            <Tooltip
              size="large"
              position={['bottom', 'end']}
              triggerEvent="click"
              className="form-tooltip"
              message={
                <>
                  <div className="tooltip-head">
                    <Heading size="body1">프롬프트 템플릿 생성 가이드</Heading>
                  </div>
                  <div className="tooltip-body">
                    <Text size="body3" weight="medium">
                      <Badge variant="filled" size="small" accent="primary" iconOnly="check" round />
                      프롬프트 내용에 다음 6가지를 포함해 주세요
                    </Text>
                    <div className="tooltip-point">페르소나, 지시문, 문맥, 예시, 포맷, 톤</div>
                    <Text>
                      너는 공부를 잘하는 [[역할 : 중학생]] 역할을해.
                      <br />
                      [[주제:AI와 미래]]에 대한 PPT 슬라이드를 만들어.
                      <br />
                      너의 청중은 [[예상 청중 : 선생님]] 이야.
                      <br />
                      각 슬라이드에는 간단한 제목을 넣어.
                      <br />
                      너의 발표는 약 [[발표시간:10분]] 이야.
                      <br />
                      약 [[슬라이드장 수:8장]] 의 슬라이드를 만들어줘.
                      <br />
                      모든 대답은 한국어로 작성해.
                    </Text>
                  </div>
                </>
              }
            />
          }
          error={errors.promptTemplate}
          isRequired
        >
          <Textarea
            variant="filled"
            size="xlarge"
            placeholder="프롬프트를 입력해 주세요."
            rows={7}
            maxLength={500}
            showCount
            name="promptTemplate"
            value={formData.promptTemplate ?? ''}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem label="사용 모델" error={errors.model} isRequired>
          <Select
            variant="filled"
            size="xlarge"
            placeholder="선택"
            name="model"
            value={formData.model ?? ''}
            options={modelOptions}
            onChange={handleSelectChange}
            full
          />
        </FormItem>
      </Form>
      <ButtonArea align="end">
        <div className="dsx-side-left">
          <Button variant="outline" size="large" prefixIcon="chevronLeft" onClick={handlePrev}>
            이전
          </Button>
        </div>
        <Tooltip
          variant="inverse"
          size="large"
          position="top"
          message={
            <>
              다음 단계로 가기 전 등록된
              <br /> <em className="dsx-color-lime">프롬프트 템플릿 테스트</em>를 필수로 진행해주세요
            </>
          }
          isOpen={testTooltipVisible}
        >
          <Button variant="secondary" size="large" onClick={handleTest} disabled={testDisabled}>
            테스트하기
          </Button>
        </Tooltip>
        <Button type="submit" variant="primary" size="large" suffixIcon="chevron" onClick={handleFormSubmit}>
          다음
        </Button>
      </ButtonArea>
    </>
  );
};

export default PromptCreateDtl;
