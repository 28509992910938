// import {
//   // Button,
//   // // ToggleButton,
//   // Tooltip,
//   makeStyles,
//   shorthands,
//   tokens,
// } from '@fluentui/react-components';
// import { Breakpoints, customTokens } from '../../../styles';
import { ChatMessageRole, IChatMessage } from '../../../libs/models/conversations/ChatMessage';
import { ChatHistoryItem } from './ChatHistoryItem';
// import { ReactComponent as Clipboard } from '../../../assets/etc-icons/icon_copy_2.svg';
// import { ReactComponent as Retry } from '../../../assets/etc-icons/icon_retry.svg';
// import { ReactComponent as MoreLeft } from '../../../assets/etc-icons/icon_expand_more_left.svg';
// import { ReactComponent as MoreRight } from '../../../assets/etc-icons/icon_expand_more_right.svg';
import React from 'react';
// import { useAppDispatch } from '../../../redux/app/hooks';
// import { retryMessageToConversationFromUser } from '../../../redux/features/conversations/conversationsSlice';
// import { LanguageModel } from '../../../components/chat/chat-input-buttons/LanguageModel';
// import { AIModelName } from '../../../libs/models/app/AIModel';
// import { RootState } from '../../../redux/app/store';

// const useClasses = makeStyles({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     maxWidth: '75%',
//     paddingTop: customTokens.spacingVerticalS,
//     ...shorthands.borderRadius(tokens.borderRadiusLarge),
//     ...shorthands.gap('3px'),
//     ...Breakpoints.small({
//       maxWidth: '100%',
//     }),
//   },
//   debug: {
//     position: 'absolute',
//     top: '-4px',
//     right: '-4px',
//   },
//   alignEnd: {
//     alignSelf: 'flex-end',
//   },
//   persona: {
//     // paddingTop: customTokens.spacingVerticalS,
//     backgroundColor: tokens.colorNeutralBackground6,
//     ...shorthands.borderRadius('50%'),
//     ...shorthands.padding('5px'),
//   },
//   item: {
//     display: 'flex',
//     wordWrap: 'break-word',
//     alignItems: 'end',
//     paddingTop: customTokens.spacingVerticalXXS,
//   },
//   me: {
//     // flexDirection: 'row-reverse',
//     ...shorthands.borderColor(tokens.colorNeutralBackground2),
//   },
//   contents: {
//     minHeight: '54px',
//     display: 'block',
//     verticalAlign: 'middle',
//     ...shorthands.borderWidth('thin'),
//     ...shorthands.borderStyle('solid'),
//     ...shorthands.borderColor(tokens.colorNeutralStroke1),
//     ...shorthands.borderRadius(tokens.borderRadiusMedium),
//     ...shorthands.padding(0, tokens.spacingHorizontalM),
//   },
//   mycontents: {
//     backgroundColor: tokens.colorNeutralBackground2,
//     ...shorthands.borderColor(tokens.colorNeutralBackground2),
//   },
//   header: {
//     position: 'relative',
//     display: 'flex',
//     flexDirection: 'row',
//     ...shorthands.gap(customTokens.spacingHorizontalL),
//   },
//   canvas: {
//     width: '100%',
//     textAlign: 'center',
//   },
//   image: {
//     maxWidth: '250px',
//   },
//   blur: {
//     filter: 'blur(5px)',
//   },
//   footer: {
//     backgroundColor: '#F4F4F4',
//     width: 'fit-content',
//     height: 'fit-content',

//     ...shorthands.borderRadius(tokens.borderRadiusSmall),
//     ...shorthands.margin('auto', tokens.spacingHorizontalXS, 0, '54px'),
//   },
//   buttons: {
//     display: 'flex',
//     flexDirection: 'row',
//   },
//   modelText: { fontSize: tokens.fontSizeBase200, color: tokens.colorNeutralShadowKey },
//   button: {},
//   name: {
//     fontWeight: 'bold',
//   },
// });

interface ChatHistoryItemsProps {
  items: IChatMessage;
  messageIndex: number;
  botStatus: boolean;
  isLast: boolean;
  isTest?: boolean;
}

export const ChatHistoryItems: React.FC<ChatHistoryItemsProps> = ({
  items,
  messageIndex,
  botStatus,
  isLast,
  isTest,
}) => {
  // const classes = useClasses();
  // const dispatch = useAppDispatch();

  const isMe = items.role === ChatMessageRole.User;
  const isBot = items.role === ChatMessageRole.System || items.role === ChatMessageRole.Assistant;
  const canRetry = isBot && isLast && items.responseFrom !== null;
  const isTyping = botStatus && isLast;

  // const [messagedCopied, setMessageCopied] = React.useState(false);
  const [currentItemId, setCurrentItemIndex] = React.useState(items.messages.length - 1);

  React.useEffect(() => {
    setCurrentItemIndex(items.messages.length - 1);
  }, [items.messages.length]);

  return (
    <ChatHistoryItem
      items={items}
      item={items.messages[currentItemId]}
      messageIndex={messageIndex}
      currentItemId={currentItemId}
      setCurrentItemIndex={setCurrentItemIndex}
      isMe={isMe}
      isBot={isBot}
      canRetry={canRetry}
      isTyping={isTyping}
      isTest={isTest}
    />
  );
};
