import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Chip } from './Chip';
import { v4 as uuidv4 } from 'uuid';

// ChipGroup 컴포넌트의 Props 타입 정의
export interface IChipOption {
  value: string;
  label: string;
  icon?: string;
}

interface ChipGroupProps {
  variant?: 'outlined' | 'filled';
  options: IChipOption[];
  value: string | string[];
  onChange?: (value: string | string[]) => void;
  multiple?: boolean;
  className?: string;
}

export const ChipGroup: React.FC<ChipGroupProps> = ({
  variant,
  options,
  value,
  onChange,
  multiple = false,
  className,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[] | string>(multiple ? value || [] : value);

  useEffect(() => {
    setSelectedValues(multiple ? value || [] : value);
  }, [value, multiple]);

  const groupName = useRef(`chip-group-${Math.random().toString(36).substring(2, 6)}`).current;

  const handleChipChange = (newValue: string) => {
    if (multiple) {
      const newSelectedValues = (selectedValues as string[]).includes(newValue)
        ? (selectedValues as string[]).filter((val) => val !== newValue)
        : [...(selectedValues as string[]), newValue];
      setSelectedValues(newSelectedValues);
      if (onChange) onChange(newSelectedValues);
    } else {
      setSelectedValues(newValue);
      if (onChange) onChange(newValue);
    }
  };

  return (
    <div className={classNames('dsx-ChipGroup', className)}>
      {options.map((option) => (
        <Chip
          variant={variant}
          key={option.value + uuidv4()}
          id={option.value}
          checked={multiple ? (selectedValues as string[]).includes(option.value) : selectedValues === option.value}
          label={option.label}
          prefixIcon={option.icon}
          onChange={() => {
            handleChipChange(option.value);
          }}
          type={multiple ? 'checkbox' : 'radio'}
          name={multiple ? undefined : groupName}
        />
      ))}
    </div>
  );
};
