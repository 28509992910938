import classNames from 'classnames';
import { ReactNode } from 'react';

interface ILinkProps {
  url?: string;
  role?: string;
  className?: string;
  children?: ReactNode;
  tabIndex?: number;
  target?: string;
  rel?: string;
  onClick?: (id: any) => void;
}

export const Link: React.FC<ILinkProps> = ({
  url = '#',
  role = 'button',
  className,
  children,
  tabIndex,
  onClick,
  target,
  rel,
  ...props
}) => {
  return (
    <a
      className={classNames('ds-link', className)}
      href={url}
      role={role}
      tabIndex={tabIndex}
      {...props}
      onClick={onClick}
      target={target}
      rel={rel}
    >
      {children}
    </a>
  );
};
