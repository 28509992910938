import { useEffect } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

// Define the props type for the Loading component
interface LoadingProps {
  position?: 'static' | 'absolute' | 'fixed';
  size?: '1' | '2' | '3' | '4' | '5';
  label?: string;
  className?: string;
  isVisible?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({
  position = 'static',
  size = '3',
  label,
  className,
  isVisible = true,
}) => {
  useEffect(() => {
    if (position === 'fixed' && isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [position, isVisible]);

  if (!isVisible) return null;

  const loadingContent = (
    <div
      className={classNames(
        'dsx-Loading',
        `dsx-Loading--size${size}`,
        className
      )}
      data-position={position}
    >
      <span className="dsx-Loading-spinner">
        <svg className="spinner-image" viewBox="0 0 20 20">
          <path
            className="path1"
            d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10Z"
            fill="#70737C"
            fillOpacity="0.28"
          />
          <path
            className="path2"
            d="M10 19C10 19.5523 10.4488 20.0052 10.9983 19.95C11.9691 19.8526 12.9222 19.6135 13.8268 19.2388C15.0401 18.7362 16.1425 17.9997 17.0711 17.0711C17.9997 16.1425 18.7362 15.0401 19.2388 13.8268C19.6135 12.9222 19.8526 11.9691 19.95 10.9983C20.0052 10.4488 19.5523 10 19 10C18.4477 10 18.0064 10.4494 17.9376 10.9974C17.8486 11.7053 17.6652 12.3996 17.391 13.0615C16.989 14.0321 16.3997 14.914 15.6569 15.6569C14.914 16.3997 14.0321 16.989 13.0615 17.391C12.3996 17.6652 11.7053 17.8486 10.9974 17.9376C10.4494 18.0064 10 18.4477 10 19Z"
            fill="#1A75FF"
          />
        </svg>
        {label && <p className="spinner-text">{label}</p>}
      </span>
    </div>
  );

  return position === 'fixed'
    ? ReactDOM.createPortal(loadingContent, document.body)
    : loadingContent;
};
