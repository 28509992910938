import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import PluginCreateDtl from './PluginCreateDtl';
import PluginCreateProfile from './PluginCreateProfile';
import PluginCreateReg from './PluginCreateReg';

const steps = ['프로필 생성', '데이터 학습', '배포'];

interface PluginCreateFormProps {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onSubmit: () => void;
  handleSave: (arg0: string) => void;
}

const PluginCreateForm: FC<PluginCreateFormProps> = ({ formData, setFormData, onSubmit, handleSave }) => {
  const [step, setStep] = useState<number>(formData.createdStep ?? 1);

  const [isTabSelectable, setIsTabSelectable] = useState(false);

  useEffect(() => {
    const tabSelectableValue =
      formData.name && formData.model && formData.profileUrl && formData.description && formData.pluginCategory;

    if (tabSelectableValue ?? step > 1) {
      setIsTabSelectable(true);
    }
  }, [step]);

  const updateFormDataWithStep = (newStep: number) => {
    setStep(newStep);
    if (formData.pluginCategoryId) {
      setFormData((prev) => ({
        ...prev,
        createdStep: newStep,
      }));
      handleSave('temp'); // Save as temporary
    }
  };

  const handleNext = () => {
    updateFormDataWithStep(step + 1);
  };

  const handlePrev = () => {
    if (step > 1) {
      updateFormDataWithStep(step - 1);
    }
  };

  const handleTabSelect = (index: number) => {
    if (isTabSelectable) {
      setStep(index + 1);
      setFormData((prev) => ({ ...prev, createdStep: index + 1 }));
      handleSave('temp'); // Save as temporary
    }
  };

  useEffect(() => {
    if (formData.createdStep && formData.createdStep !== step) {
      setStep(formData.createdStep);
    }
  }, [formData.createdStep, step]);

  return (
    <>
      <div className="form-step">
        <ol className="step-list" role="img" aria-label={`총 ${steps.length}단계 중 ${step}단계 ${steps[step - 1]}`}>
          {steps.map((stepName, index) => (
            <li
              key={index}
              className={classNames(
                index + 1 < step ? 'is-complete' : '',
                index + 1 === step ? 'is-active' : '',
                isTabSelectable ? 'selectable' : '',
              )}
              onClick={() => {
                handleTabSelect(index);
              }}
            >
              <span className="step-name">{stepName}</span>
            </li>
          ))}
        </ol>
      </div>
      <div className="form-panel">
        {step === 1 && <PluginCreateProfile formData={formData} setFormData={setFormData} onNext={handleNext} />}
        {step === 2 && (
          <PluginCreateDtl formData={formData} setFormData={setFormData} onPrev={handlePrev} onNext={handleNext} />
        )}
        {step === 3 && (
          <PluginCreateReg formData={formData} setFormData={setFormData} onPrev={handlePrev} onSubmit={onSubmit} />
        )}
      </div>
    </>
  );
};

export default PluginCreateForm;
