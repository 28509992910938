// import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactNode, FC } from 'react';
type Position = 'top' | 'right' | 'bottom' | 'left';

interface ITooltipProps {
  children: ReactNode;
  className?: string;
  message: ReactNode;
  theme?: string;
  position?: Position[] | Position;
}

export const Tooltip: FC<ITooltipProps> = ({ children, message, className, theme, position = 'bottom' }) => {
  const positions = Array.isArray(position) ? position : [position];

  const positionClass = () => {
    return `tooltip-${positions.map((pos) => pos.charAt(0)).join('')}`;
  };

  return (
    <div className={className ? className : 'tooltip-wrap'}>
      {children}
      <div className={classNames('ds-tooltip', { [`ds-tooltip-${theme}`]: theme }, positionClass())}>
        <span className="tooltip-desc">{message}</span>
      </div>
    </div>
  );
};
