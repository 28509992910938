import classNames from 'classnames';
import React, { ChangeEvent, CSSProperties, forwardRef, useEffect, useState, useRef } from 'react';
import Text from './Text';

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: 'outlined' | 'filled';
  size?: 'large' | 'xlarge' | null;
  rows?: number;
  maxRows?: number;
  placeholder?: string;
  value?: string;
  className?: string;
  style?: CSSProperties;
  invalid?: boolean;
  maxLength?: number;
  showCount?: boolean;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string;
  typingState?: (isTyping: boolean) => void;
}

// forwardRef를 사용한 Textarea 컴포넌트 정의
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  {
    variant = 'outlined',
    size,
    rows = 3,
    maxRows = rows,
    placeholder,
    value,
    className,
    style,
    invalid,
    maxLength = 1000,
    showCount = false,
    onChange,
    name,
    typingState,
    ...props
  },
  ref,
) {
  const [textarea, setTextarea] = useState<string>(value ?? '');
  const [isTyping] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [nowRows, setRows] = useState(rows);

  useEffect(() => {
    if (typingState) typingState(isTyping);

    return () => {
      if (typingState) typingState(false);
    };
  }, [isTyping, typingState]);

  // value prop 변경 시 상태 업데이트
  useEffect(() => {
    setTextarea(value ?? '');
  }, [value]);

  // textarea 변경 시 상태 업데이트 및 onChange 호출
  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= maxLength) {
      setTextarea(event.target.value);
      if (onChange) {
        onChange(event);
      }
      const textareaLineHeight = 27;
      const previousRows = event.target.rows;
      const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight); // 현재 행 수 계산
      if (currentRows !== previousRows) {
        if (currentRows >= maxRows) {
          event.target.rows = maxRows;
        } else {
          event.target.rows = currentRows; // scrollHeight에 맞게 행 수 조정
        }
        setRows(currentRows < maxRows ? currentRows : maxRows);
      }
      if (event.target.value.length < 10) {
        event.target.rows = rows; // 텍스트가 없을 시 최소 한 행으로 설정
        setRows(rows);
      }
    }
  };

  return (
    <>
      <div
        className={classNames(
          'dsx-Textarea',
          `dsx-Textarea--${size}`,
          `dsx-Textarea--${variant}`,
          invalid && 'is-invalid',
          className,
        )}
        style={style}
      >
        <textarea
          className={classNames('dsx-Textarea-field', className)}
          ref={(node) => {
            if (ref) {
              if (typeof ref === 'function') {
                ref(node);
              } else {
                ref.current = node;
              }
            }
            textareaRef.current = node;
          }}
          placeholder={placeholder}
          value={textarea}
          onChange={handleTextareaChange}
          rows={nowRows}
          maxLength={maxLength}
          name={name}
          {...props}
        />
      </div>
      {showCount && maxLength && (
        <Text variant="length">
          {textarea.length}/{maxLength}
        </Text>
      )}
    </>
  );
});

Textarea.displayName = 'Textarea';

export default Textarea;
