import { getFriendlyChatName } from '../../../libs/hooks/useChat';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Conversations } from '../../../libs/models/conversations/ConversationsState';
import { ChatListItem } from './ChatListItem';
import { useState } from 'react';
import classNames from 'classnames';

type ChangeOpenFunction = (state: boolean) => boolean;

interface IChatListSectionProps {
  header?: string;
  conversations: Conversations;
  toggleOpen: ChangeOpenFunction;
}

export const ChatListSection: React.FC<IChatListSectionProps> = ({ header, conversations, toggleOpen }) => {
  const { selectedId } = useAppSelector((state: RootState) => state.conversations);
  const [isOpen, setOpen] = useState(true);

  const keys = Object.keys(conversations);

  return keys.length > 0 ? (
    <>
      <h3
        className="chat-date"
        onClick={() => {
          setOpen(toggleOpen(isOpen));
        }}
      >
        {header}
        <span className={classNames('arrow', { isClose: !isOpen })}> </span>
      </h3>
      <ol className={`chat-list  ${isOpen ? '' : 'blind'}`}>
        {keys.map((id) => {
          const convo = conversations[id];
          const isSelected = id === selectedId;
          return (
            <ChatListItem
              id={convo.id}
              key={id}
              isSelected={isSelected}
              name={getFriendlyChatName(convo)}
              timestamp={convo.createdTime}
            />
          );
        })}
      </ol>
    </>
  ) : null;
};
