import { useState, useEffect } from 'react';
import {
  Form,
  FormItem,
  InputHashtag,
  ButtonArea,
  Button,
  Tooltip,
  Heading,
  Text,
  Badge,
  Input,
  IconButton,
} from '../../../components/base';
import RadioGroup from '../../../components/base/RadioGroup';
import { EOpenScopeType, IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';

const options = [
  {
    value: 'Public',
    label: '전체공개',
  },
  {
    value: 'Private',
    label: '나만보기',
  },
  {
    value: 'Part',
    label: '일부공개 (조직 선택 or 링크공유)',
    disabled: true,
  },
];

interface Errors {
  openScope: string;
  ragContentsData: string;
}

interface IPluginCreateReg {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onPrev: () => void;
  onSubmit: () => void;
}

const PluginCreateReg: React.FC<IPluginCreateReg> = ({ formData, setFormData, onPrev, onSubmit }) => {
  const [errors, setErrors] = useState<Errors>({
    openScope: '',
    ragContentsData: '',
  });

  useEffect(() => {
    // 컴포넌트가 마운트될 때 conversationStarters가 비어있으면 기본 값 설정
    if (!formData.conversationStarters || formData.conversationStarters.length === 0) {
      setFormData((prev) => ({
        ...prev,
        conversationStarters: [''], // 기본 값
      }));
    }
  }, [formData.conversationStarters, setFormData]);

  // 대화스타터 추가 (최대 5개)
  const addQuestion = () => {
    if ((formData.conversationStarters?.length ?? 0) < 5) {
      setFormData((prev) => ({
        ...prev,
        conversationStarters: [...(prev.conversationStarters ?? []), ''],
      }));
    }
  };

  // 대화스타터 삭제
  const removeQuestion = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      conversationStarters: prev.conversationStarters?.filter((_, i) => i !== index),
    }));
  };

  // 대화스타터 내용 변경
  const handleQuestionChange = (index: number, content: string) => {
    setFormData((prev) => ({
      ...prev,
      conversationStarters: prev.conversationStarters?.map((q, i) => (i === index ? content : q)),
    }));
  };

  const handleRadioChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    let openScope: EOpenScopeType | undefined;
    switch (value) {
      case 'Public':
        openScope = EOpenScopeType.Public;
        break;
      case 'Private':
        openScope = EOpenScopeType.Private;
        break;
      default:
        openScope = undefined;
    }

    if (openScope !== undefined) {
      setFormData((prev) => ({ ...prev, openScope }));
    }
  };

  const handleHashtagChange = (newHashtags: string[]) => {
    setFormData((prev) => ({ ...prev, tags: newHashtags }));
  };

  const validate = () => {
    const newErrors: Errors = {
      openScope: '',
      ragContentsData: '',
    };
    if (!formData.openScope) newErrors.openScope = '공개 범위를 선택해주세요.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePrev = () => {
    onPrev();
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) onSubmit();
  };

  return (
    <>
      <Form variant="vertical" className="form-field" onSubmit={handleFormSubmit}>
        <FormItem
          label="대화스타터"
          desc="대화스타터 입력은 최대 5개까지 가능해요"
          info={
            <Tooltip
              size="large"
              position={['bottom', 'end']}
              triggerEvent="click"
              className="form-tooltip"
              message={
                <div className="tooltip-head">
                  <Heading size="body1">대화 스타터</Heading>
                  <Text>
                    대화 스타터는 플러그인 선택 시 사용자가 <br /> 어떤 질문을 할 수 있는지에 대한 예시로 사용됩니다.
                  </Text>
                </div>
              }
            />
          }
        >
          <div className="dsx-cont-box">
            {(formData.conversationStarters ?? []).map((question, index) => (
              <div key={index} className="form-questions">
                <Badge size="large">대화스타터 {index + 1}</Badge>
                <div className="question-input">
                  <Input
                    size="xlarge"
                    placeholder="내용을 입력해 주세요."
                    maxLength={40}
                    showCount
                    full
                    value={question}
                    onChange={(e) => {
                      handleQuestionChange(index, e.target.value);
                    }}
                  />
                  <IconButton
                    name="closeFill"
                    size="large"
                    className="q-del"
                    onClick={() => {
                      removeQuestion(index);
                    }}
                    disabled={(formData.conversationStarters?.length ?? 0) <= 1}
                  >
                    대화스타터 {index + 1} 삭제
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
          <ButtonArea align="end">
            <Button
              size="large"
              variant="secondary"
              prefixIcon="plusFill"
              onClick={addQuestion}
              disabled={(formData.conversationStarters?.length ?? 0) >= 5}
            >
              대화스타터 추가
            </Button>
          </ButtonArea>
        </FormItem>
        <FormItem label="공개범위" error={errors.openScope} isRequired>
          <RadioGroup size="large" options={options} value={formData.openScope} onChange={handleRadioChange} />
        </FormItem>
        <FormItem label="해시태그" desc="해시태그 입력은 최대 3개까지 가능해요">
          <InputHashtag
            size="xlarge"
            variant="filled"
            placeholder="단어 입력 후 엔터를 통해 해시 태그를 입력해 주세요"
            name="tags"
            value={formData.tags ?? []}
            onChange={handleHashtagChange}
            maxTags={3}
            showCount
          />
        </FormItem>
      </Form>
      <ButtonArea align="end">
        <div className="dsx-side-left">
          <Button variant="outline" size="large" prefixIcon="chevronLeft" onClick={handlePrev}>
            이전
          </Button>
        </div>
      </ButtonArea>
    </>
  );
};

export default PluginCreateReg;
