import { Icon } from '../../../components/Icon';
import { IChatMessageItem } from '../../../libs/models/conversations/ChatMessage';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { NewChatPrompt } from './NewChatPrompt';

interface ChatPluginStarterProps {
  data: IChatMessageItem;
  isDisabled: boolean;
  useInfo: boolean;
  onClick: (data: any) => void;
}

export const ChatPluginStarter: React.FC<ChatPluginStarterProps> = ({ data, isDisabled, useInfo, onClick }) => {
  const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);

  return (
    <div className="chat-starter">
      {data.conversationStarters?.map((item, index) => (
        <button
          key={index}
          className={`chat-starter-item ${isDisabled ? 'disabled' : ''}`}
          onClick={() => {
            const updatedData = { ...data, message: item };
            if (!isDisabled) onClick(updatedData);
          }}
        >
          <span className="name">{item}</span> <Icon name="send" />
        </button>
      ))}
      {useInfo && conversations[selectedId].messages.data.length > 1 ? <NewChatPrompt /> : null}
    </div>
  );
};
