import { jwtDecode } from 'jwt-decode';
import { UserData } from '../models/users/UsersState';
import { BaseService } from './BaseService';
import { IChatVisual } from '../models/users/ChatVisual';

export class UserService extends BaseService {
  public getToken = async (): Promise<string> => {
    const cookie: string = this.getAccessToken();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const WORKS_TOKEN = params.get('sso_token');

    let token: string | null = null;

    if (cookie) {
      try {
        const user: UserData = jwtDecode(cookie);
        const currentTime = Date.now() / 1000;
        if (user.exp > currentTime && user.userId) {
          token = cookie;
        }
      } catch {}
    }

    if (WORKS_TOKEN != null || token == null) {
      const body = {
        token: WORKS_TOKEN,
      };
      const result = await this.getResponseAsync<{ message: string; result: string; status: string }>({
        commandPath: `api/sso`,
        method: 'POST',
        body,
      });
      token = result.result;
    }

    // AuthHelper.setCookie("AI_TOKEN", token);
    this.setAccessToken(token);
    return token;
  };

  public getChatVisual = async (latitude?: number, longtitude?: number): Promise<IChatVisual> => {
    const query = new URLSearchParams();
    if (latitude) query.append('latitude', latitude.toString());
    if (longtitude) query.append('longtitude', longtitude.toString());

    const result = await this.getResponseAsync<IChatVisual>({
      commandPath: `api/user/insight`,
      method: 'GET',
      query: query,
    });
    return result;
  };
}
