import React, { useState, useRef, forwardRef, ChangeEvent } from 'react';
import classNames from 'classnames';

interface CheckboxProps {
  id?: string;
  name?: string;
  value?: string;
  children: React.ReactNode;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  size?: string;
  only?: boolean;
  indeterminate?: boolean;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      name,
      value,
      children,
      defaultChecked,
      checked,
      disabled,
      invalid,
      size = 'large',
      only,
      indeterminate,
      className,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [privateChecked, setPrivateChecked] = useState<boolean>(defaultChecked ?? false);
    const controlled = useRef<boolean>(typeof checked !== 'undefined').current;
    const isChecked = controlled ? (disabled ? privateChecked : checked) : privateChecked;

    const checkboxId = useRef<string>(id ?? `checkbox-${Math.random().toString(36).substring(2, 6)}`).current;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        event.preventDefault();
        return;
      }

      if (!controlled) {
        setPrivateChecked(event.target.checked);
      }
      if (onChange) {
        onChange(event);
      }
    };

    return (
      <div className={classNames('dsx-Checkbox', size && `dsx-Checkbox--${size}`, invalid && 'is-invalid', className)}>
        <input
          type="checkbox"
          id={checkboxId}
          name={name}
          className={classNames('dsx-Checkbox-input', indeterminate && 'is-indeterminate')}
          value={value}
          checked={isChecked}
          disabled={disabled}
          aria-invalid={invalid}
          onChange={handleChange}
          ref={ref}
          {...props}
        />
        <label htmlFor={checkboxId} className={classNames('dsx-Checkbox-label', only && 'dsx-blind')}>
          {children}
        </label>
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
