import React, { forwardRef } from 'react';
import Icon from './Icon';
import classNames from 'classnames';

// Badge 컴포넌트의 Props 타입 정의
interface BadgeProps {
  variant?: 'filled' | 'tint' | 'outlined' | 'ghost' | 'ring';
  size?: 'dot' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  round?: boolean;
  accent?: 'primary' | 'positive' | 'negative' | 'caution' | 'info' | 'normal' | 'violet';
  position?: 'topStart' | 'topEnd' | 'bottomStart' | 'bottomEnd';
  prefixIcon?: string;
  suffixIcon?: string;
  iconOnly?: string;
  children?: React.ReactNode;
  className?: string;
}

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      variant = 'tint',
      size = 'medium',
      round = false,
      children,
      prefixIcon,
      suffixIcon,
      iconOnly,
      className,
      accent,
      position,
      ...props
    },
    ref,
  ) => {
    return (
      <span
        className={classNames(
          'dsx-Badge',
          `dsx-Badge--${variant}`,
          `dsx-Badge--${size}`,
          round && 'dsx-radius-max',
          className,
        )}
        ref={ref}
        data-accent={accent}
        data-position={position}
        aria-label={iconOnly ? (children as string) : undefined} // Ensure aria-label is a string if iconOnly is set
        {...props}
      >
        {iconOnly ? (
          <Icon name={iconOnly} />
        ) : (
          <>
            {prefixIcon && <Icon name={prefixIcon} />}
            {children}
            {suffixIcon && <Icon name={suffixIcon} />}
          </>
        )}
      </span>
    );
  },
);

Badge.displayName = 'Badge'; // forwardRef 컴포넌트에 displayName을 설정합니다.

export default Badge;
