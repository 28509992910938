import React from 'react';
import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

interface TimeDisplayProps {
    updatedTime: Date;
}

const TimeDisplay: React.FC<TimeDisplayProps> = ({ updatedTime }) => {
    const updatedMoment = moment(updatedTime);
    const now = moment();

    const duration = moment.duration(now.diff(updatedMoment));
    const minutes = duration.asMinutes();
    const hours = duration.asHours();
    const days = duration.asDays();

    let displayTime: string;

    if (minutes < 60) {
        // 1시간 이내
        displayTime = `${Math.floor(minutes)}분 전`;
    } else if (hours < 24) {
        // 당일
        displayTime = `${Math.floor(hours)}시간 전`;
    } else if (days < 7) {
        // 1주일 이내
        displayTime = `${Math.floor(days)}일 전`;
    } else {
        // 1주일 이후
        displayTime = updatedMoment.format('YY-MM-DD');
    }

    return <span>{displayTime}</span>;
};

export default TimeDisplay;

