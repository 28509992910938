import {
    BrandVariants,
    GriffelStyle,
    PartialTheme,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    shorthands,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';
import './assets/font/NanumGothic.css';
import './assets/font/NotoSansKr.css';
import { FluentIconsProps } from '@fluentui/react-icons';

export const semanticKernelBrandRamp: BrandVariants = {
    10: '#060103',
    20: '#261018',
    30: '#431426',
    40: '#591732',
    50: '#701A3E',
    60: '#861F4B',
    70: '#982C57',
    80: '#A53E63',
    90: '#B15070',
    100: '#BC627E',
    110: '#C6748B',
    120: '#CF869A',
    130: '#D898A8',
    140: '#E0AAB7',
    150: '#E8BCC6',
    160: '#EFCFD6',
};

export const semanticKernelLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(semanticKernelBrandRamp),
    colorMeBackground: '#e8ebf9',
};

export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(semanticKernelBrandRamp),
    colorMeBackground: '#2b2b3e',
};

export const customTheme: PartialTheme = {
    colorNeutralBackground1: '#ffffff',
    colorNeutralBackground2: '#f2f2f2',
    colorNeutralBackground3: '#eaeaea33',
    colorNeutralBackground1Pressed: '#444444',
    colorNeutralShadowKey: '#222222CC',
    colorNeutralStroke1: '#DEDEDE',
    colorNeutralStroke2: '#DDDDDD',
    colorNeutralStroke1Pressed: '#444444',
    colorNeutralStrokeAccessiblePressed: '#494949',
    colorNeutralCardBackground: '#494949',
    colorNeutralBackground1Hover: '#444444',
    colorNeutralForeground1: '#000000',
    colorNeutralForeground1Hover: '#ffffff',
    colorNeutralForeground1Pressed: '#ffffff',
    // colorNeutralForeground2: '#0344CE',
    colorNeutralForeground2BrandHover: '#DEDEDE',
    colorPaletteRedBackground1: '#FFE8E8',
    colorPaletteBlueBackground2: '#DCEAFB',
    colorPaletteRoyalBlueBackground2: '#5B68FF',
    colorPaletteYellowForeground2: '#FF9800',
    colorPaletteYellowBackground1: '#FEF7D1',
    colorPaletteGreenForeground3: '#28A34B',
    colorPaletteGreenBackground1: '#E5F7E6',
    // colorNeutralBackground6: '#ffffff',
    colorNeutralBackground6: '#f4f4f4',
    colorPaletteRedForeground3: '#E53E3E',
    colorBrandForeground1: '#f0f4f9',
    colorNeutralBackgroundInverted: '#0074ffcc',
    colorNeutralForegroundInverted: 'white',
    colorTransparentStroke: '#494949',

    borderRadiusCircular: '50px',
    borderRadiusSmall: '10px',
    borderRadiusMedium: '16px',
    borderRadiusLarge: '23.5px',

    fontSizeBase400: '18px',
    fontSizeBase300: '15px',
    fontSizeBase200: '13px',
    fontFamilyBase: 'Noto Sans KR, NanumGothic, sans-serif',
    fontFamilyNumeric: 'Noto Sans KR, NanumGothic, sans-serif',
    fontFamilyMonospace: 'Noto Sans KR, NanumGothic, sans-serif',
    fontWeightMedium: 500,

    spacingVerticalXXS: '3px',
    spacingVerticalXS: '7px',
    spacingVerticalS: '16px',
    spacingVerticalM: '21px',
    spacingVerticalL: '28px',
    spacingHorizontalXS: '7px',
    spacingHorizontalS: '12px',
    spacingHorizontalM: '24px',
    spacingHorizontalL: '28px',

    lineHeightBase200: '0.9',

    shadow16: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

export const customTokens = themeToTokensObject(semanticKernelLightTheme);

export const Breakpoints = {
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'auto',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralStrokeAccessiblePressed,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground3,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
    overflowEllipsis: {
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '1',
    },
    alert: {
        minHeight: 'auto',
        ...shorthands.borderStyle('solid'),
        ...shorthands.borderWidth('thin'),
        ...shorthands.padding('10px', '16px', '10px', '16px'),
        ...shorthands.borderRadius('5px'),
    },
    error: {
        backgroundColor: tokens.colorPaletteRedBackground1,
        ...shorthands.borderColor(tokens.colorPaletteRedForeground3),
    },
    info: {
        backgroundColor: tokens.colorPaletteBlueBackground2,
        ...shorthands.borderColor(tokens.colorNeutralForeground2),
    },
    warning: {
        backgroundColor: tokens.colorPaletteYellowBackground1,
        ...shorthands.borderColor(tokens.colorPaletteYellowForeground2),
    },
    success: {
        backgroundColor: tokens.colorPaletteGreenBackground1,
        ...shorthands.borderColor(tokens.colorPaletteGreenForeground3),
    },
    table: {
        '> table': {
            borderCollapse: 'collapse',
            boxShadow: '0 0 0 1px #E7E7E9',
            fontSize: tokens.fontSizeBase300,
            ...shorthands.overflow('hidden'),
            ...shorthands.borderRadius('8px'),
            ...shorthands.borderStyle('hidden'),
            '> thead th': {
                ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
                ...shorthands.border('1px', 'solid', '#E7E7E9'),
                textAlign: 'left',
                fontWeight: 'bold',
                backgroundColor: '#767676',
                color: tokens.colorNeutralBackground1,
                fontSize: tokens.fontSizeBase200,
            },
            '> th, td': {
                ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
                ...shorthands.border('1px', 'solid', '#E7E7E9'),
                backgroundColor: tokens.colorNeutralBackground1,
                textAlign: 'left',
            },
        },
    },
    link: {
        '> a': {},
    },
};

export const MenuStyle: Record<string, GriffelStyle> = {
    menuList: {
        backgroundColor: tokens.colorNeutralBackground1,
        minWidth: '128px',
        minHeight: '48px',
        width: 'max-content',
        boxShadow: tokens.shadow16,
        paddingTop: '4px',
        paddingBottom: '4px',
    },
    menuItem: {
        alignItems: 'center',
        ...shorthands.padding(tokens.spacingHorizontalS),
        ...shorthands.gap(tokens.spacingHorizontalXS),
        ...shorthands.gap('4px'),
        ':hover': {
            backgroundColor: tokens.colorBrandForeground1,
            '&:active': {
                backgroundColor: tokens.colorBrandForeground1,
            },
        },
    },
    model: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: 'bold',
        color: tokens.colorNeutralForeground1,
        ...shorthands.padding(tokens.spacingVerticalXXS, 0),
        ':hover': {
            color: tokens.colorNeutralForeground1,
        },
    },
    description: {
        color: tokens.colorNeutralShadowKey,
        fontSize: tokens.fontSizeBase200,
    },
};

export const useSharedClasses = makeStyles({
    informativeView: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('100px'),
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalXL),
        marginTop: tokens.spacingVerticalXXXL,
    },
});

export const useDialogClasses = makeStyles({
    surface: {
        paddingRight: tokens.spacingVerticalXS,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
        width: '100%',
    },
    paragraphs: {
        marginTop: tokens.spacingHorizontalS,
    },
    innerContent: {
        height: '100%',
        ...SharedStyles.scroll,
        paddingRight: tokens.spacingVerticalL,
    },
    text: {
        whiteSpace: 'pre-wrap',
        textOverflow: 'wrap',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});

export const IconHover: FluentIconsProps = {
    className: 'icon',
    primaryFill: '#4A4B57',
};
