import React, { useRef, useState, forwardRef, useEffect, ChangeEvent } from 'react';
import classNames from 'classnames';

interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(({
  id,
  checked,
  disabled,
  className,
  onChange,
  ...props
}, ref) => {
  const switchId = useRef(id ?? `switch-${Math.random().toString(36).substring(2, 6)}`).current;

  const [internalChecked, setInternalChecked] = useState(checked ?? false);

  useEffect(() => {
    if (checked !== undefined) {
      setInternalChecked(checked);
    }
  }, [checked]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    if (checked === undefined) {
      setInternalChecked(event.target.checked);
    }
  };

  return (
    <div className={classNames('dsx-Switch', className)}>
      <input 
        type="checkbox" 
        id={switchId} 
        className="dsx-Switch-input" 
        checked={internalChecked} 
        disabled={disabled} 
        onChange={handleChange} 
        ref={ref}
        {...props}
      />
      <label htmlFor={switchId} className="dsx-Switch-label" />
    </div>
  );
});

Switch.displayName = 'Switch';