import React from 'react';
import classNames from 'classnames';

// Heading 컴포넌트의 Props 타입 정의
// extends React.HTMLProps<HTMLElement>
interface HeadingProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'strong';
  size:
    | 'display1'
    | 'display2'
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'title1'
    | 'title2'
    | 'title3'
    | 'body1'
    | 'body2'
    | 'body3';
  weight?: 'bold' | 'semibold' | 'medium' | 'regular';
  children: React.ReactNode;
  className?: string;
}

export const Heading: React.FC<HeadingProps> = ({
  as = 'strong',
  size = 'heading1',
  weight = 'bold',
  children,
  className,
  ...props
}) => {
  const Tag = as as keyof JSX.IntrinsicElements;

  return (
    <Tag className={classNames('dsx-Heading', `dsx-Heading--${size}`, `dsx-weight-${weight}`, className)} {...props}>
      {children}
    </Tag>
  );
};

export default Heading;
