import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Icon from './Icon';
import { Input } from './Input';
import { Button } from './Button';
import { Select } from './Select';

interface PaginationProps {
  variant?: 'default' | 'outline';
  pageRange?: number;
  totalResults: number;
  resultsPerPage?: number;
  page?: number;
  mode?: 'basic' | 'ellipsis';
  pageSizes?: number[];
  options?: Array<'total' | 'sizes' | 'jump' | 'end'>;
  setPage?: (page: number) => void;
  setPageSize?: (size: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  variant = 'default',
  pageRange = 5,
  totalResults,
  resultsPerPage = 10,
  page = 1,
  mode = 'basic',
  pageSizes = [10, 50, 100],
  options = [], // 'total', 'sizes', 'jump', 'end'
  setPage,
  setPageSize,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [pageInput, setPageInput] = useState<string>(page.toString());
  const [currentResultsPerPage, setCurrentResultsPerPage] = useState<number>(resultsPerPage);
  const totalPages = Math.ceil(totalResults / currentResultsPerPage);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setPageInput(currentPage.toString());
    if (setPage) setPage(currentPage);
  }, [currentPage, setPage]);

  const handleFirstPage = () => { setCurrentPage(1); };
  const handleLastPage = () => { setCurrentPage(totalPages); };
  const handlePrevPage = () => {
    if (mode === 'ellipsis') {
      setCurrentPage((prev) => Math.max(prev - 1, 1));
    } else {
      const newPage = Math.max(currentPage - pageRange, 1);
      setCurrentPage(newPage);
    }
  };
  const handleNextPage = () => {
    if (mode === 'ellipsis') {
      setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    } else {
      const newPage = Math.min(currentPage + pageRange, totalPages);
      setCurrentPage(newPage);
    }
  };
  const handlePageClick = (page: number) => { setCurrentPage(page); };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(event.target.value);
  };

  const handlePageJump = () => {
    if (totalPages <= 0) return; // Prevent jumping to a negative page

    let pageNumber = parseInt(pageInput, 10);
    if (isNaN(pageNumber) || pageNumber < 1) {
      pageNumber = 1;
    } else if (pageNumber > totalPages) {
      pageNumber = totalPages;
    }
    setCurrentPage(pageNumber);
    setPageInput(pageNumber.toString());
  };

  const handlePageSizeChange = (newSize: number) => {
    setCurrentResultsPerPage(newSize);
    setCurrentPage(1); // Reset to first page on size change
    if (setPageSize) setPageSize(newSize);
  };

  const renderPageNumbersEllipsis = () => {
    const pageNumbers: Array<number | string> = [];
    const visiblePages = pageRange - 2;

    if (totalPages <= visiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfVisiblePages = Math.floor(visiblePages / 2);
      let startPage = Math.max(currentPage - halfVisiblePages, 2);
      const endPage = Math.min(startPage + visiblePages - 1, totalPages - 1);

      if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(endPage - visiblePages + 1, 2);
      }

      pageNumbers.push(1);

      if (startPage > 2) {
        pageNumbers.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          type="button"
          className={classNames('dsx-Paging-button', { active: page === currentPage })}
          aria-current={page === currentPage ? 'page' : undefined}
          onClick={() => { handlePageClick(page); }}
        >
          {page}
        </button>
      ) : (
        <span key={index} className="dsx-Paging-text">
          {page}
        </span>
      ),
    );
  };

  const renderPageNumbersRange = () => {
    const pageNumbers: number[] = [];
    const startPage = Math.floor((currentPage - 1) / pageRange) * pageRange + 1;
    const endPage = Math.min(startPage + pageRange - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers.map((page) => (
      <button
        key={page}
        type="button"
        className={classNames('dsx-Paging-button', { active: page === currentPage })}
        aria-current={page === currentPage ? 'page' : undefined}
        onClick={() => { handlePageClick(page); }}
      >
        {page}
      </button>
    ));
  };

  return (
    <div className={classNames('dsx-Pagination', `dsx-Pagination--${variant}`)}>
      <div className="dsx-PagingTotal">
        {options.includes('total') && <span className="dsx-Paging-text">총 {totalResults}건</span>}
        {options.includes('sizes') && (
          <Select
            placeholder="선택"
            options={pageSizes.map((size) => ({ value: size, label: `${size}건` }))}
            defaultValue={resultsPerPage}
            value={currentResultsPerPage}
            onChange={(value) => { handlePageSizeChange(value as number); }}
            style={{ minWidth: '7rem' }}
          />
        )}
      </div>
      <span className="dsx-Paging">
        {options.includes('end') && (
          <button
            type="button"
            className="dsx-Paging-button dsx-Paging-button--arrow"
            aria-label="첫 페이지"
            onClick={handleFirstPage}
            disabled={currentPage === 1}
          >
            <Icon name="chevronFirst" />
          </button>
        )}
        <button
          type="button"
          className="dsx-Paging-button dsx-Paging-button--arrow"
          aria-label="이전 페이지"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <Icon name="chevronLeft" />
        </button>
        <span className="dsx-Paging-items">
          {mode === 'ellipsis' ? renderPageNumbersEllipsis() : renderPageNumbersRange()}
        </span>
        <button
          type="button"
          className="dsx-Paging-button dsx-Paging-button--arrow"
          aria-label="다음 페이지"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <Icon name="chevron" />
        </button>
        {options.includes('end') && (
          <button
            type="button"
            className="dsx-Paging-button dsx-Paging-button--arrow"
            aria-label="마지막 페이지"
            onClick={handleLastPage}
            disabled={currentPage === totalPages}
          >
            <Icon name="chevronLast" />
          </button>
        )}
      </span>
      {options.includes('jump') && (
        <span className="dsx-PagingJump">
          <Input type="text" value={pageInput} onChange={handleInputChange} /> / {totalPages}
          <Button variant="secondary" onClick={handlePageJump}>
            이동
          </Button>
        </span>
      )}
    </div>
  );
};
