import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { AlertDialog } from '../components/base';

// Alert의 타입 정의
interface Alert {
  title: string;
  message: ReactNode;
  onConfirm?: () => void;
  confirmLabel?: string;
  showCancel?: boolean;
}

// AlertContext의 타입 정의
interface AlertContextType {
  showAlert: (
    alert: Omit<Alert, 'confirmLabel' | 'showCancel'> & { confirmLabel?: string; showCancel?: boolean },
  ) => void;
  removeAlert: () => void;
}

// AlertContext 생성
const AlertContext = createContext<AlertContextType | undefined>(undefined);

export interface AlertProviderProps {
  children?: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  // AlertDialog Component
  const showAlert = useCallback(({ title, message, onConfirm, confirmLabel = '확인', showCancel = false }: Alert) => {
    setAlerts((prev) => [...prev, { title, message, onConfirm, confirmLabel, showCancel }]);
  }, []);

  const removeAlert = useCallback(() => {
    setAlerts((prev) => prev.slice(1));
  }, []);

  return (
    <AlertContext.Provider value={{ showAlert, removeAlert }}>
      {children}

      {/* AlertDialog Component */}
      {alerts.map((alert, index) => (
        <AlertDialog
          key={index}
          title={alert.title}
          message={alert.message}
          confirmLabel={alert.confirmLabel}
          onConfirm={() => {
            if (alert.onConfirm) alert.onConfirm();
            removeAlert();
          }}
          onCancel={alert.showCancel ? removeAlert : undefined}
        />
      ))}
    </AlertContext.Provider>
  );
};

// Alert Hook
export const useAlert = (): AlertContextType => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};
