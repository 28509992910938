// import PropTypes from 'prop-types';
import { FC } from 'react';

interface IIconProps {
  name: string | undefined;
}

export const Icon: FC<IIconProps> = ({ name }) => {
  return <i className={`ds-icon ds-icon-${name}`}></i>;
};
