import { createPortal } from 'react-dom';
// import PropTypes from 'prop-types';

import { Icon } from './Icon';

import { FC } from 'react';
import { useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';

interface IAlertToastProps {
  buttonName?: string;
  onClick?: () => void;
}

export const AlertToast: FC<IAlertToastProps> = () => {
  // const { toastVisible, toastMessage } = useAlertStore();
  const { toastMessage, toastVisible } = useAppSelector((state: RootState) => state.app);

  if (!toastVisible || toastMessage.length === 0) return null;

  const modalRoot = document.getElementById('modal-root');
  if (!modalRoot) {
    throw new Error('Modal root element not found');
  }

  return createPortal(
    <div className="ds-modal ds-modal-toast">
      {toastMessage.map((toast, index) => (
        <p key={index} className={`toast_message toast-${toast.altType}`} role="alert">
          {/* <Icon name={toast.type === ('normal' || 'warning') ? 'warning' : toast.type} /> */}
          <Icon name={toast.altType} />
          {toast.altMessage}
        </p>
      ))}
    </div>,
    modalRoot,
  );
};
