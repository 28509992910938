import { FC, ReactNode } from 'react';

interface IDropdownHeaderProps {
  title: string | ReactNode;
  as?: keyof JSX.IntrinsicElements; // allows using any HTML tag such as 'h1', 'h2', etc.
  size?: 'small' | 'default' | 'large';
  children?: ReactNode;
}

export const DropdownHeader: FC<IDropdownHeaderProps> = ({ title, as = 'h3', size = 'default', children }) => {
  const Tag = as;
  const classes = `ds-heading${size !== 'default' ? ` ds-heading--${size}` : ''}`;

  return (
    <div className="ds-dropdown-header">
      <Tag as={as} className={classes}>
        {title}
      </Tag>
      {children}
    </div>
  );
};
