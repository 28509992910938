import React, { useState, useEffect, useRef, useCallback, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { IconButton } from './IconButton';
import AnimationState from './AnimationState';

type Position = 'top' | 'right' | 'bottom' | 'left';
type PositionModifier = 'start' | 'end';

interface TooltipProps {
  children?: React.ReactElement;
  message: ReactNode;
  variant?: 'default' | 'inverse' | 'primary';
  size?: 'small' | 'medium' | 'large';
  position?: Position | [Position, PositionModifier];
  triggerEvent?: 'hover' | 'click';
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  message,
  variant,
  size,
  position = 'bottom',
  triggerEvent = 'hover',
  isOpen,
  onOpen,
  onClose,
  className,
  ...props
}) => {
  const positions = Array.isArray(position) ? position : [position];
  const isControlled = isOpen !== undefined;
  const [isShow, setIsShow] = useState(isControlled ? isOpen : false);
  const [isDragging, setIsDragging] = useState(false);
  const targetRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const showTooltip = () => {
    if (!isControlled) {
      setIsShow(true);
      onOpen?.();
    }
  };
  const hideTooltip = () => {
    if (!isControlled) {
      setIsShow(false);
      onClose?.();
    }
  };

  const toggleTooltip = () => {
    if (!isControlled) {
      setIsShow((prevIsShow) => {
        const newState = !prevIsShow;
        if (newState) {
          onOpen?.();
        } else {
          onClose?.();
        }
        return newState;
      });
    }
  };

  useEffect(() => {
    if (isControlled) {
      setIsShow(isOpen);
    }
  }, [isOpen, isControlled]);

  const setTooltipPosition = useCallback(() => {
    const target = targetRef.current;
    const tooltip = tooltipRef.current;

    if (!target || !tooltip) return;

    const { top, left, width, height } = target.getBoundingClientRect();
    const tooltipWidth = tooltip.offsetWidth;
    const tooltipHeight = tooltip.offsetHeight;
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;
    const baseSpacing = 10;

    let posX = left + scrollX;
    let posY = top + scrollY;
    let arrowPosX = tooltipWidth / 2;
    let arrowPosY = 0;
    let arrowRotate = 45;

    switch (positions[0]) {
      case 'top':
        posY -= tooltipHeight + baseSpacing;
        posX += width / 2 - tooltipWidth / 2;
        arrowPosY = tooltipHeight;
        break;
      case 'right':
        posY += height / 2 - tooltipHeight / 2;
        posX += width + baseSpacing;
        arrowPosX = 0;
        arrowPosY = tooltipHeight / 2;
        arrowRotate = 135;
        break;
      case 'bottom':
        posY += height + baseSpacing;
        posX += width / 2 - tooltipWidth / 2;
        break;
      case 'left':
        posY += height / 2 - tooltipHeight / 2;
        posX -= tooltipWidth + baseSpacing;
        arrowPosX = tooltipWidth;
        arrowPosY = tooltipHeight / 2;
        arrowRotate = 135;
        break;
      default:
        break;
    }

    if (positions.length > 1) {
      switch (positions[1]) {
        case 'start':
          if (positions[0] === 'top' || positions[0] === 'bottom') {
            posX -= width / 2 - tooltipWidth / 2;
            arrowPosX = width / 2;
          } else {
            posY -= height / 2 - tooltipHeight / 2;
            arrowPosY = height / 2;
          }
          break;
        case 'end':
          if (positions[0] === 'top' || positions[0] === 'bottom') {
            posX += width / 2 - tooltipWidth / 2;
            arrowPosX = tooltipWidth - width / 2;
          } else {
            posY += height / 2 - tooltipHeight / 2;
            arrowPosY = tooltipHeight - height / 2;
          }
          break;
        default:
          break;
      }
    }

    tooltip.style.top = `${posY}px`;
    tooltip.style.left = `${posX}px`;
    tooltip.style.setProperty('--dsx-tooltip-arrow-position-top', `${arrowPosY}px`);
    tooltip.style.setProperty('--dsx-tooltip-arrow-position-left', `${arrowPosX}px`);
    tooltip.style.setProperty('--dsx-tooltip-arrow-transform', `rotate(${arrowRotate}deg)`);
  }, [positions]);

  useEffect(() => {
    const target = targetRef.current;

    const handleEvent = (event: Event) => {
      if (isControlled) return; // 컨트롤드 모드에서는 이벤트 무시

      if (triggerEvent === 'hover') {
        if (event.type === 'mouseover' || event.type === 'focusin') {
          showTooltip();
        } else if (event.type === 'mouseout' || event.type === 'focusout') {
          if (!isDragging) hideTooltip();
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (triggerEvent === 'click' && event.type === 'click') {
        toggleTooltip();
      }
    };

    const handleMouseDown = () => {
      setIsDragging(true);
    };
    const handleMouseUp = (event: MouseEvent) => {
      setIsDragging(false);
      if (tooltipRef.current && targetRef.current) {
        if (!tooltipRef.current.contains(event.target as Node) && !targetRef.current.contains(event.target as Node)) {
          hideTooltip();
        }
      }
    };

    if (target) {
      target.addEventListener('mouseover', handleEvent);
      target.addEventListener('mouseout', handleEvent);
      target.addEventListener('focusin', handleEvent);
      target.addEventListener('focusout', handleEvent);
      target.addEventListener('click', handleEvent);
      target.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (target) {
        target.removeEventListener('mouseover', handleEvent);
        target.removeEventListener('mouseout', handleEvent);
        target.removeEventListener('focusin', handleEvent);
        target.removeEventListener('focusout', handleEvent);
        target.removeEventListener('click', handleEvent);
        target.removeEventListener('mousedown', handleMouseDown);
      }
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [triggerEvent, isDragging, isControlled]);

  const handleAnimationStart = () => {
    if (isShow) {
      setTooltipPosition();
    }
  };

  useEffect(() => {
    const target = targetRef.current;

    // 위치 재설정 핸들러
    const handleReposition = () => {
      setTooltipPosition();
    };

    // 창 크기 변경 이벤트
    window.addEventListener('resize', handleReposition);

    // 스크롤 이벤트 발생 시 위치 다시 잡기
    window.addEventListener('scroll', handleReposition);
    let parent = target?.parentElement;
    while (parent) {
      parent.addEventListener('scroll', handleReposition);
      parent = parent.parentElement;
    }

    return () => {
      window.removeEventListener('resize', handleReposition);
      window.removeEventListener('scroll', handleReposition);

      let parent = target?.parentElement;
      while (parent) {
        parent.removeEventListener('scroll', handleReposition);
        parent = parent.parentElement;
      }
    };
  }, []);

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          ref: targetRef,
          'aria-haspopup': 'true',
        })
      ) : (
        <IconButton name="info" size="small" ref={targetRef} aria-haspopup="true">
          툴팁
        </IconButton>
      )}

      {ReactDOM.createPortal(
        <AnimationState isActive={isShow} nodeRef={tooltipRef} onStart={handleAnimationStart}>
          <div
            role="tooltip"
            ref={tooltipRef}
            className={classNames(
              'dsx-Tooltip',
              { [`dsx-Tooltip--${variant}`]: variant },
              { [`dsx-Tooltip--${size}`]: size },
              className,
            )}
            {...props}
          >
            {/* {triggerEvent === 'click' && (
              <IconButton name="close" size="small" className="dsx-Tooltip-close" onClick={hideTooltip}>
                닫기
              </IconButton>
            )} */}
            <div className="dsx-Tooltip-desc">{message}</div>
          </div>
        </AnimationState>,
        document.body,
      )}
    </>
  );
};
