import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { PluginAPI, Plugins } from '../../../libs/models/app/Plugin';
import { checkMobileKate } from '../../../components/utils/common';
import { Tag } from '../../../components/Tag';
import { Button } from '../../../components/Button';
import { DropdownHeader } from '../../../components/DropdownHeader';
import { Text } from '../../../components/base/Text';
import { Avatar, Empty } from '../../../components/base';
import classNames from 'classnames';

interface ModelListProps {
  data: Plugins;
  selectedPluginId: string;
  searchQuery: string;
  onSelect: (id: string) => void;
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
}
// ModelList
export const ModelList = forwardRef<{ handleKeyDown: (event: React.KeyboardEvent) => void } | null, ModelListProps>(
  ({ data, selectedPluginId, searchQuery, onSelect, isOpen, setIsOpen }, ref) => {
    const datas = Object.values(data);
    const [filteredList, setFilteredList] = useState<PluginAPI[]>(datas); // 초기값을 filteredData로 설정
    const [hoverIndex, setHoverIndex] = useState(0);
    const [isDesktop, setIsDesktop] = useState(!checkMobileKate() || window.innerWidth <= 768);
    const listRef = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
      setIsDesktop(window.innerWidth > 768);
    }, []);

    useEffect(() => {
      // searchQuery가 있을 경우 필터링된 리스트를 생성
      const filtered = searchQuery
        ? datas.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
        : datas;

      // 필터링된 리스트를 setFilteredList에 설정
      setFilteredList(filtered);

      setHoverIndex(
        selectedPluginId
          ? Math.max(
              // 음수가 안나오도록
              filtered.findIndex((item) => item.id === selectedPluginId),
              0,
            )
          : 0,
      );
    }, [searchQuery, selectedPluginId]);

    useEffect(() => {
      const listElement = listRef.current;

      if (listElement) {
        const selectedElement = listElement.querySelector(
          `.model-list-item:nth-child(${hoverIndex + 1})`,
        ) as HTMLElement;
        selectedElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }, [hoverIndex]);

    const setClose = () => {
      if (setIsOpen) setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      handleKeyDown(event: React.KeyboardEvent) {
        switch (event.key) {
          case 'ArrowDown':
            setHoverIndex((prevIndex) => (prevIndex + 1) % filteredList.length);
            event.preventDefault();
            break;

          case 'ArrowUp':
            setHoverIndex((prevIndex) => (prevIndex - 1 + filteredList.length) % filteredList.length);
            event.preventDefault();
            break;

          case 'Enter':
            if (hoverIndex >= 0 && hoverIndex < filteredList.length) {
              onSelect(filteredList[hoverIndex].id);
              setClose();
              event.preventDefault();
            }
            break;

          case 'Escape':
            setClose();
            event.preventDefault();
            break;
        }
      },
    }));

    return (
      <ul ref={listRef} tabIndex={0} role="listbox" className="ds-popover" data-state={isOpen ? 'show' : 'hide'}>
        <DropdownHeader title="즐겨찾는 플러그인">
          {isDesktop ? (
            <Button
              classOnly="chat-button-edit"
              onClick={() => {
                const options =
                  'location=no,menubar=no,toolbar=yes,scrollbars=no,resizable=yes,width=1600,height=800,left=200,top=200'; // 팝업 창의 크기와 위치 및 설정
                window.open('/makers/plugin', '_blank', options);
                setClose();
              }}
            >
              편집하기
            </Button>
          ) : (
            <Button className="ds-control-close" control textBlind onClick={setClose}>
              닫기
            </Button>
          )}
        </DropdownHeader>

        {filteredList.length === 0 ? (
          <Empty
            image="images/ico_load.svg"
            desc={
              <>
                <Text as="strong" weight="semibold">
                  즐겨찾기한 플러그인이 없습니다
                </Text>
                <br />
                플러그인 즐겨찾기 시 빠른 활용이 가능해요
              </>
            }
          >
            {/* <Button theme="secondary" size="small"> */}
            {isDesktop && (
              <Button
                theme="secondary"
                onClick={() => {
                  const options =
                    'location=no,menubar=no,toolbar=yes,scrollbars=no,resizable=yes,width=1600,height=800,left=200,top=200'; // 팝업 창의 크기와 위치 및 설정
                  window.open('/makers/plugin', '_blank', options);
                }}
              >
                플러그인 보러가기
              </Button>
            )}
          </Empty>
        ) : (
          <>
            <div className="model-list">
              {filteredList.filter(c => !c.hidden).map((item: PluginAPI, index) => (
                <div
                  key={index}
                  tabIndex={0}
                  className={classNames('model-list-item', { hover: index === hoverIndex })}
                >
                  <a
                    className="model-item"
                    aria-selected={item.id === selectedPluginId}
                    data-disabled={item.isAvailable ?? false}
                    // tabIndex={item.isAvailable ? undefined : -1}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                      e.preventDefault();
                      onSelect(item.id);
                      setClose;
                    }}
                  >
                    <Avatar src={item.profileUrl} label="" />

                    <div className="title">
                      {/* {item.displayName} {item.tag && <em className="ds-tag type-notice">{item.tag}</em>} */}
                      <strong className="name">{item.name}</strong>
                      {item.tags?.map((tag, index) => (
                        <Tag key={index} type="notice" className="suffix">
                          {tag}
                        </Tag>
                      ))}
                      <span className="desc">{item.description}</span>
                    </div>
                  </a>
                </div>
              ))}
              {!isDesktop && (
                <Text size="caption1" className="ds-text-info">
                  플러그인 목록 편집은 pc환경에서만 가능해요
                </Text>
              )}
            </div>
          </>
        )}
      </ul>
    );
  },
);

ModelList.displayName = 'ModelList';
