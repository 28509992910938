import { useState, useRef, useEffect, useMemo } from 'react';
import { Heading, Text, Empty, Avatar, Badge } from '../../../components/base';
import Icon from '../../../components/base/Icon';
import { ECreatedStatusType, IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import { ChatTextArea } from '../../../components/ChatTextArea';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { ChatMessageType, IChatMessage } from '../../../libs/models/conversations/ChatMessage';
import { showAlertToast } from '../../../redux/features/app/appSlice';
import { AlertType } from '../../../libs/models/AlertType';
import { useChat } from '../../../libs/hooks/useChat';
import { ChatHistoryItems } from '../../chat/chat-history/ChatHistoryItems';
import { resetConversation, stopStreaming } from '../../../redux/features/conversations/conversationsSlice';
import { Button } from '../../../components/Button';
interface PluginCreatePreviewProps {
  formData: IPluginPromptData;
  handleSave: (arg0: string) => void;
}

interface GetResponseOptions {
  messageType: ChatMessageType;
  value: string;
  chatId: string;
  model: string;
  plugin?: string;
  cacheMode?: boolean;
  isDraft?: boolean;
}

const PluginCreatePreview: React.FC<PluginCreatePreviewProps> = ({ formData, handleSave }) => {
  const hasBasicInfo = formData.profileUrl ?? formData.name ?? formData.description ?? formData.model;
  const isTestable = formData.profileUrl && formData.name && formData.description && formData.model;

  const ChatTextareaRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useAppDispatch();
  const chat = useChat();
  const { user } = useAppSelector((state: RootState) => state.users);
  const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);

  const [createdStatus, setCreatedStatus] = useState<ECreatedStatusType | string | undefined>(undefined);
  const [requestMessages, setRequestMessages] = useState<string[]>([]);
  const [testResult, setTestResult] = useState(0);
  const [isTestLimit, setIsTestLimit] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [value, setValue] = useState('');
  const botStatus = useMemo(() => {
    return selectedId === '' ? false : conversations[selectedId].botResponseStatus;
  }, [conversations, selectedId]);

  const messages = useMemo(() => {
    return selectedId === ''
      ? { data: [] as IChatMessage[], continuationToken: null as string | null }
      : conversations[selectedId].messages;
  }, [conversations, selectedId]);

  const message = messages.data;

  useEffect(() => {
    switch (formData.createdStatus) {
      case ECreatedStatusType.Registration:
      case 'Registration':
        setCreatedStatus('Registration');
        break;
      case ECreatedStatusType.Learning:
      case 'Learning':
        setCreatedStatus('Learning');
        break;
      case ECreatedStatusType.LearningComplete:
      case 'LearningComplete':
        setCreatedStatus('LearningComplete');
        break;
      case ECreatedStatusType.Complete:
      case 'Complete':
        setCreatedStatus('Complete');
        break;
      default:
        console.error('Invalid status');
    }
  }, [formData.createdStatus]);

  useEffect(() => {
    try {
      if (formData.id) {
        dispatch(resetConversation(formData.id));
      }
    } catch (error) {}
  }, [formData.id]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (botStatus) {
      // botStatus가 true인 동안 0.5초마다 a를 실행
      interval = setInterval(() => {
        scrollToBottom();
      }, 500);
    } else if (messages.data.length === 0) {
      // ChatInsight 화면일땐 최상단으로
    } else {
      // botStatus가 false로 변할 때 a를 마지막으로 한 번 실행
      scrollToBottom();
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [botStatus]);

  // smooth scroll
  const easeInOutQuad = (t: number) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  const scrollToBottom = (duration_time = 600) => {
    const chatMain = document.querySelector('.view-chat');
    if (chatMain) {
      const startY = chatMain.scrollTop;
      const endY = chatMain.scrollHeight;
      const duration = duration_time; // 애니메이션 지속 시간 (밀리초)
      const startTime = performance.now();

      const animateScroll = (currentTime: number) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easingProgress = easeInOutQuad(progress);
        chatMain.scrollTop = startY + (endY - startY) * easingProgress;

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };
      requestAnimationFrame(animateScroll);
    }
  };

  const focusTextarea = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (!target.closest('button')) {
      ChatTextareaRef.current?.focus();
    }
  };
  const handleTypingState = (state: boolean) => {
    setIsTyping(state);
  };

  const handleSendMessage = () => {
    handleSave('temp');
    sendTestPluginMessage();
  };

  const sendTestPluginMessage = () => {
    if (!ChatTextareaRef.current) return;

    if (testResult === 100) {
      setIsTestLimit(true);
      return;
    }

    ChatTextareaRef.current.value = ''; // 입력창 비우기

    if (value.trim()) {
      setRequestMessages((prev) => [...prev, value]); // 새로운 요청 메시지를 배열에 추가

      const options: GetResponseOptions = {
        value: value,
        messageType: ChatMessageType.ChatMessage,
        chatId: formData.id ?? '1',
        model: formData.model ?? 'default_model',
        plugin: formData.id ?? '1',
        cacheMode: true,
        isDraft: true,
      };

      const encoder = new TextEncoder();
      const length = encoder.encode(JSON.stringify(options));

      if (length.byteLength > 30000) {
        dispatch(
          showAlertToast({
            altMessage: '작성한 내용이 너무 길어요. 수정한 후 다시 시도해주세요.',
            altType: AlertType.Info,
          }),
        );
        return;
      }
      handleSubmit(options);
      setValue('');
    }
  };

  const handleSubmit = (options: GetResponseOptions) => {
    try {
      const createChatResult = chat.createTestChat(options.chatId, user.userId); // Ensure it's awaited properly
      if (createChatResult) {
        chat.getResponse(options); // Ensure it's awaited properly
      }
    } catch (err) {
      console.error(err); // Logging error safely
    }
    setTestResult((prev) => prev + 1);
    scrollToBottom();
  };

  // const starterOnClick = (value: string) => {
  //   setValue(value);
  //   sendTestPluginMessage;
  // };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (createdStatus === 'LearningComplete' || createdStatus === 'Complete' || isTestable) {
      if (event.key === 'Enter' && !event.shiftKey && !botStatus) {
        event.preventDefault();
        handleSendMessage();
      }
    }
  };

  const deleteChat = () => {
    if (requestMessages.length !== 0) {
      stopResponse();
      dispatch(resetConversation(formData.id ?? '1'));
    }
    setRequestMessages([]);
  };

  const stopResponse = () => {
    dispatch(stopStreaming());
  };

  return (
    <>
      {createdStatus === 'Learning' ? (
        <div className="view-info view-info--inactive">
          <Heading size="body2" weight="semibold">
            플러그인 테스트
          </Heading>
          <Text size="label2">플러그인 테스트를 위해 자료를 학습중입니다. 조금만 기다려 주세요.</Text>
        </div>
      ) : isTestable ? (
        <div className="view-info view-info--primary">
          <Heading size="body2" weight="semibold">
            플러그인 테스트
          </Heading>
          <Text size="label2">플러그인 테스트를 진행해 주세요. 총 100회까지 가능합니다.</Text>
        </div>
      ) : (
        <div className="view-info">
          <Heading size="body2" weight="semibold">
            플러그인 테스트
          </Heading>
          <Text size="label2">학습데이터를 입력 후 테스트가 가능합니다.</Text>
        </div>
      )}
      <div className="view-chat">
        {hasBasicInfo && requestMessages.length === 0 ? (
          <div className="plugin-profile">
            {formData.profileUrl && (
              <Avatar
                className="fit-height"
                size="6"
                src={
                  formData.profileUrl &&
                  `${formData.profileUrl}?v=${formData.profileUrlVersion ? formData.profileUrlVersion : ''}`
                }
                label="플러그인 프로필 이미지"
              />
            )}
            {formData.name && (
              <Heading size="title1" weight="semibold">
                {formData.name}
              </Heading>
            )}
            {formData.model && <Badge size="large">{formData.model}</Badge>}
            {formData.description && (
              <Text as="pre" size="label1">
                {formData.description}
              </Text>
            )}
            {/*
            2024.09.26 : 플러그인 테스트 채팅에서 스타터 넣으려다 시간 부족으로 주석
            {formData.conversationStarters && (
              <ChatPluginStarter
                data={formData.conversationStarters}
                isDisabled={conversations[selectedId].botResponseStatus}
                onClick={starterOnClick}
              />
            )} */}
          </div>
        ) : isTestable ?? createdStatus !== 'Learning' ? (
          <section className="chat-history">
            <div className="chat-history-item">
              {message.map((items, index) => (
                <ChatHistoryItems
                  key={items.id}
                  messageIndex={index}
                  items={items}
                  isLast={message.length - 1 === index}
                  botStatus={botStatus}
                  isTest={true}
                />
              ))}
            </div>
          </section>
        ) : (
          <Empty
            image="images/ico_load.svg"
            desc={
              <>
                왼쪽 화면에서
                <br />
                기본 정보를 입력해 주세요!
              </>
            }
          />
        )}
        <div className="chat-bottom">
          <div className="chat-input" onClick={focusTextarea}>
            <ChatTextArea
              ref={ChatTextareaRef}
              placeholder={botStatus ? '답변을 작성하고 있어요.' : '플러그인 테스트를 해보세요'}
              maxRows={4}
              typingState={handleTypingState}
              setValue={setValue}
              disabled={!isTestable || createdStatus === 'Learning' || isTestLimit}
              handleKeyDown={handleKeyDown}
            />
            <div className="input-option plugin-input-option">
              <div className="enter-area">
                {botStatus ? (
                  <Button
                    className="chat-stop"
                    icon={selectedId === '' ? 'send' : 'pause'}
                    control
                    textBlind
                    onClick={stopResponse}
                  >
                    답변 정지
                  </Button>
                ) : (
                  <Button
                    className="chat-send"
                    icon="send"
                    control
                    textBlind
                    disabled={!isTyping || isTestLimit || value.trim() === ''}
                    onClick={handleSendMessage}
                  >
                    보내기
                  </Button>
                )}
                {/* <button
                  type="button"
                  className="ds-control chat-send"
                  disabled={!isTyping || isTestLimit}
                  onClick={handleSendMessage}
                >
                  <i className="ds-icon ds-icon-send"></i>
                  <span className="blind">보내기</span>
                </button> */}
              </div>
              {message.length > 0 && (
                <button type="button" className="btn-reset" onClick={deleteChat}>
                  대화 초기화
                </button>
              )}
            </div>
          </div>
          <div className="chat-warning">
            <Icon name="infoFill" />
            답변에 잘못된 정보가 있을 수 있습니다. 중요한 정보는 확인해 주세요.
            <p className="txt-length">
              테스트 가능 횟수{' '}
              <span>
                <b className="txt-point">{100 - testResult}</b>/100
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PluginCreatePreview;
