import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICategoryList {
  value: string;
  label: string;
  icon?: string;
}

export interface Category {
  id: string;
  companyCode: string | null;
  categoryName: string | null;
  description: string | null;
  createdMember: null;
  deletedTime: Date | string;
  type: string | null;
  _etag: null;
  partitionKey: string | null;
  updatedTime: Date | string;
  createdTime: Date | string;
  deleted: boolean;
}

export interface CategoriesState {
  categoryList: ICategoryList[];
}

const initialState: CategoriesState = {
  categoryList: [],
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryModel: (state: CategoriesState, action: PayloadAction<ICategoryList[]>) => {
      if (state.categoryList.length > 0) {
        state.categoryList = [state.categoryList[0], ...action.payload];
      } else {
        state.categoryList = [...action.payload];
      }
    },
    resetCategoryList(state) {
      state.categoryList = []; // 초기화할 값으로 설정
    },
  },
});

export const { setCategoryModel, resetCategoryList } = categorySlice.actions;

export default categorySlice.reducer;
