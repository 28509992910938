import classNames from 'classnames';
import React, { CSSProperties, ForwardedRef, useEffect, useRef, useState } from 'react';
import Icon from './Icon';
import { Popover } from './Popover';

interface Option {
  value: string | number;
  label: React.ReactNode;
}

interface SelectProps {
  size?: 'small' | 'large' | 'xlarge';
  variant?: 'outlined' | 'filled' | 'ghost';
  placeholder?: string;
  defaultValue?: string | number;
  value: string | number;
  options: Option[];
  className?: string;
  style?: CSSProperties;
  full?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onChange?: (value: string | number) => void;
  name?: string;
}

export const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      size,
      variant = 'outlined',
      placeholder,
      defaultValue,
      value,
      options,
      className,
      style,
      full = false,
      disabled = false,
      invalid = false,
      onChange,
      name,
      ...props
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [selectedValue, setSelectedValue] = useState<string | number | undefined>(value || '');
    const [popoverState, setPopoverState] = useState(false);
    const internalRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      setSelectedValue(value || defaultValue);
    }, [value]);

    const changedPopoverState = (state: boolean) => {
      setPopoverState(state);
    };

    const handleOptionClick = (value: string | number) => {
      setSelectedValue(value);
      if (onChange) {
        onChange(value);
      }
      changedPopoverState(false);
    };

    // ref 병합
    const selectRef = (node: HTMLDivElement) => {
      internalRef.current = node;
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    };

    return (
      <div
        ref={selectRef}
        className={classNames(
          'dsx-Select',
          size && `dsx-Select--${size}`,
          `dsx-Select--${variant}`,
          full && 'dsx-w-full',
          invalid && 'is-invalid',
          className,
        )}
        data-state={popoverState ? 'show' : 'hide'}
        style={style}
      >
        <Popover
          trigger={
            <button
              type="button"
              role="combobox"
              className={classNames(`dsx-Select-combobox`, !selectedValue && 'is-placeholder')}
              aria-expanded={popoverState}
              disabled={disabled}
              {...props}
            >
              <span className="dsx-Select-value">
                {selectedValue ? options.find((option) => option.value === selectedValue)?.label : placeholder}
              </span>
              <Icon name="chevronBottom" className="dsx-Select-arrow" />
            </button>
          }
          isOpen={popoverState}
          popoverState={changedPopoverState}
          style={{ minWidth: internalRef.current?.offsetWidth }}
        >
          {options.length > 0 && (
            <div
              role="listbox"
              className={classNames('dsx-Select-listbox', size && `dsx-Select-listbox--${size}`)}
              data-state={popoverState ? 'show' : 'hide'}
            >
              {options.map((option) => (
                <button
                  key={option.value}
                  className="dsx-Select-option"
                  role="option"
                  aria-selected={selectedValue === option.value}
                  name={name}
                  onClick={() => {
                    handleOptionClick(option.value);
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </Popover>
      </div>
    );
  },
);

Select.displayName = 'Select';
