import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';

interface ButtonAreaProps {
  align?: 'start' | 'center' | 'end' | 'full' | 'vertical';
  children: ReactNode;
  className?: string;
}

export const ButtonArea = forwardRef<HTMLDivElement, ButtonAreaProps>(
  ({ align, children, className, ...props }, ref) => {
    return (
      <div
        ref={ref} // ref를 div에 연결
        className={classNames('dsx-ButtonArea', align && `dsx-align-${align}`, className)}
        {...props}
      >
        {children}
      </div>
    );
  },
);

ButtonArea.displayName = 'ButtonArea';

export default ButtonArea;
