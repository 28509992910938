import { useEffect, useRef, useState } from 'react';
import { IconUpload } from '../../../assets/icons';
import {
  Badge,
  Button,
  ButtonArea,
  Form,
  FormItem,
  Heading,
  IconButton,
  Text,
  Textarea,
} from '../../../components/base';
import FileUploader, { FileUploaderHandle } from '../../../components/base/FileUploader';
import Icon from '../../../components/base/Icon';
import Image from '../../../components/Image';
//import { useAlert } from '../../../context/AlertContext';
import { bytesToSize, getFileIcon } from '../../../libs/common';
import { usePlugin } from '../../../libs/hooks/usePlugin';
import { AlertType } from '../../../libs/models/AlertType';
import { IApiResult } from '../../../libs/models/app/APIResult';
import {
  ECreatedStatusType,
  IFileData,
  IinputOutput,
  IPluginFileSave,
  IPluginPromptData,
} from '../../../libs/models/studio/PluginPromptList';
import { useAppDispatch } from '../../../redux/app/hooks';
import { showAlertToast } from '../../../redux/features/app/appSlice';
import PluginLearningData from './PluginLearningData';

interface IPluginCreateDtl {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onPrev: () => void;
  onNext: () => void;
}

interface FileType {
  name: string;
  size: number;
  type: string;
  status: 'uploading' | 'uploaded' | 'uploadFailed' | 'learning' | 'learningSuccess' | 'learningFailed' | 'removing';
  originalFile?: File;
}

// [File Uploader - Fake] 파일 업로드 & 러닝 가상 시뮬레이션
const statusToKorean: { [key in FileType['status']]: string } = {
  uploading: '업로드중',
  uploaded: '업로드 완료',
  uploadFailed: '업로드 실패',
  learning: '학습중',
  learningSuccess: '학습 성공',
  learningFailed: '학습 실패',
  removing: '삭제중',
};

const PluginCreateDtl: React.FC<IPluginCreateDtl> = ({ formData, setFormData, onPrev, onNext }) => {
  const plugin = usePlugin();
  const dispatch = useAppDispatch();
  //const { showAlert } = useAlert();

  const [ragContentsData, setRagContentsData] = useState<IinputOutput[]>(formData.ragContentsData ?? []);
  const [inputData, setInputData] = useState('');
  const [outputData, setOutputData] = useState('');
  const isRegistable = inputData && outputData;

  // [File Uploader]
  const fileRef = useRef<FileUploaderHandle | null>(null);

  const [files, setFiles] = useState<FileType[]>([]);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    setFiles(
      (formData.ragFiles ?? []).map((ragFile: IFileData) => ({
        name: ragFile.fileName,
        size: ragFile.fileSizeLong ?? 0,
        type: ragFile.fileName,
        status: ragFile.hasError ? 'learningFailed' : ragFile.isCompleted ? 'learningSuccess' : 'uploading', // isCompleted 가 true 면 학습완료, false 면 업로드중
      })),
    );

    // const fetchPluginData = async () => {
    //   try {
    //     const res = await plugin.getPlugin(formData.id as string);

    //     setFormData((prev) => ({
    //       ...prev,
    //       ragFiles: Array.from(new Set([...(prev.ragFiles ?? []), ...(res.ragFiles ?? [])])),
    //     }));
    //   } catch (error) {
    //     console.error('Error fetching plugin data:', error);
    //   }
    // };

    // void fetchPluginData();
  }, []);

  useEffect(() => {
    if (formData.ragContentsData) {
      setRagContentsData(formData.ragContentsData);
    }
  }, [formData]);

  useEffect(() => {
    setFormData((prev) => {
      const newIds = Array.from(
        new Set(
          ragContentsData.map((item) => item.id).filter((id): id is string => id !== null && id !== undefined), // null, undefined 값 제거
        ),
      );

      if (
        JSON.stringify(prev.ragContentsData) !== JSON.stringify(ragContentsData) ||
        JSON.stringify(prev.ragContentsDataIds) !== JSON.stringify(newIds)
      ) {
        return {
          ...prev,
          ragContentsData: ragContentsData,
          ragContentsDataIds: newIds,
          ragContentsDataCount: newIds.length,
        };
      }
      return prev;
    });
  }, [ragContentsData]);

  // const validate = () => {
  //   if (files.length === 0 && ragContentsData.length === 0) {
  //     showAlert({
  //       title: '학습 데이터 등록을 진행해 주세요',
  //       message: '학습 데이터 등록 후 다음단계로 이동 가능합니다.',
  //       confirmLabel: '확인',
  //       showCancel: false,
  //     });
  //     return false;
  //   } else if (files.find((item) => item.status === 'learning')) {
  //     showAlert({
  //       title: '데이터 학습 진행중입니다.',
  //       message: '학습 데이터가 완료된 후 다음단계로 이동 가능합니다.',
  //       confirmLabel: '확인',
  //       showCancel: false,
  //     });
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  const handlePrev = () => {
    onPrev();
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //if (validate()) onNext();
    onNext();
  };

  const fileChange = async (newFiles: File[]) => {
    const updatedFiles = Array.from(newFiles).map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      status: 'uploading' as const, // 초기상태
      originalFile: file,
    }));

    setFiles((prev) => {
      const prevFilesMap = new Map(prev.map((file) => [file.name, file]));
      updatedFiles.forEach((updatedFile) => {
        if (prevFilesMap.has(updatedFile.name)) {
          prevFilesMap.delete(updatedFile.name);
        }
        prevFilesMap.set(updatedFile.name, updatedFile);
      });
      return Array.from(prevFilesMap.values());
    });

    for (const updatedFile of updatedFiles) {
      await onFileUpload(updatedFile);
    }
  };

  const fileDelete = (file: FileType): void => {
    setFiles((prev) => prev.filter((f) => f.name !== file.name));
  };

  const handleFileRemove = async (file: FileType, idx: number): Promise<void> => {
    const customPluginId = formData.id as string;
    const documentId: string = formData.ragFileIds?.[idx] ?? '';

    setFiles((prev) => prev.map((f) => (f.name === file.name ? { ...f, status: 'removing' } : f)));

    try {
      // 파일 삭제
      await plugin.deletePluginRagFile(customPluginId, documentId);
      setFiles((prev) => prev.filter((f) => f.name !== file.name));
    } catch (e) {
      dispatch(
        showAlertToast({
          altMessage: '파일 삭제에 실패했습니다. 잠시 후 다시 시도해 주세요.',
          altType: AlertType.Info,
        }),
      );
    }

    setFormData((prev) => ({
      ...prev,
      ragFileIds: prev.ragFileIds?.filter((id) => id !== documentId),
      ragFiles: prev.ragFiles?.filter((file) => file.documentId !== documentId),
    }));
  };

  const checkFileUploadCompleted = () => {
    if (formData.ragFiles && formData.ragFiles.length > 0) {
      // 모든 파일이 완료되었고, 에러가 없는지 확인
      const allCompleted = formData.ragFiles.every((ragfile) => ragfile.isCompleted && !ragfile.hasError);

      // 하나라도 학습 중이거나 에러가 있으면 학습 중으로 상태 변경
      if (allCompleted) {
        setFormData((prev) => ({
          ...prev,
          createdStatus: ECreatedStatusType.LearningComplete, // 모든 파일 학습 완료
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          createdStatus: ECreatedStatusType.Learning, // 학습 중
        }));
      }
    }
  };

  const onFileUpload = async (file: FileType) => {
    setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'learning' } : f)));

    // 폼 데이터를 학습 중으로 상태 변경
    setFormData((prev) => ({
      ...prev,
      createdStatus: ECreatedStatusType.Learning, // 학습 중
    }));

    const customPluginId = formData.id as string;
    const notDownloadable = false;
    const isDraft = formData.versionStatus === 'Draft';

    try {
      // 파일 업로드
      const res: IPluginFileSave = await plugin.postPluginRagFile(
        { customPluginId, notDownloadable, isDraft },
        file.originalFile as File,
      );

      if (res.value.status === 'Error') {
        setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'learningFailed' } : f)));
        setFormData((prev) => ({
          ...prev,
          createdStatus: ECreatedStatusType.Registration, // 등록중
        }));
      } else if (!res.value.hasError) {
        // 업로드 성공 시 상태를 'uploaded'로 설정
        // setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'uploaded' } : f)));

        // 학습 상태로 변경
        // setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'learning' } : f)));

        // 학습 성공 시 상태를 'learningSuccess'로 설정
        setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'learningSuccess' } : f)));

        // 첨부파일 id, files 값 셋팅
        setFormData((prev) => ({
          ...prev,
          createdStatus: ECreatedStatusType.LearningComplete, // 학습완료
          ragFileIds: Array.from(new Set([...(prev.ragFileIds ?? []), res.value.documentId])),
          ragFiles: Array.from(new Set([...(prev.ragFiles ?? []), res.value])),
        }));
      } else {
        setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'learningFailed' } : f)));
        setFormData((prev) => ({
          ...prev,
          createdStatus: ECreatedStatusType.Learning, // 학습중
        }));
      }

      // 업로드 완료 여부 확인
      checkFileUploadCompleted();
    } catch (error: unknown) {
      // 업로드 실패 시 상태를 'uploadFailed'로 설정
      setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'uploadFailed' } : f)));
      handleError(error);
    }
  };
  const ERROR_MESSAGES: Record<string, string> = {
    DRM: '문서DRM이 적용된 문서의 경우 DRM해제 후 업로드해주세요.',
    NotSupported: '지원되지 않는 확장자입니다.',
    'Payload Too Large': '파일 용량이 허용 용량을 초과하였습니다. 용량을 확인해주세요.',
    Default: '파일 업로드가 실패하였습니다. 사내 보안 정책에 따라 파일 업로드가 차단될 수 있습니다.',
  };

  const handleError = (error: unknown) => {
    const apiError = error as IApiResult;

    const message = apiError.status in ERROR_MESSAGES ? ERROR_MESSAGES[apiError.status] : ERROR_MESSAGES.Default;
    dispatch(
      showAlertToast({
        altMessage: message,
        altType: AlertType.Info,
      }),
    );

    // 에러 타입 구조 개선
    // if ((error as IApiResult).status === 'IsDRM') {
    //   dispatch(
    //     showAlertToast({
    //       altMessage: '문서DRM이 적용된 문서의 경우 DRM해제 후 업로드해주세요.',
    //       altType: AlertType.Info,
    //     }),
    //   );
    // } else if ((error as IApiResult).status === 'NotSupported') {
    //   dispatch(
    //     showAlertToast({
    //       altMessage: '지원되지 않는 확장자입니다.',
    //       altType: AlertType.Info,
    //     }),
    //   );
    // } else if ((error as IApiResult).status === 'Payload Too Large') {
    //   dispatch(
    //     showAlertToast({
    //       altMessage: '파일 용량이 허용 용량을 초과하였습니다. 용량을 확인해주세요.',
    //       altType: AlertType.Info,
    //     }),
    //   );
    // } else {
    //   dispatch(
    //     showAlertToast({
    //       altMessage: '파일 업로드가 실패하였습니다. 사내 보안 정책에 따라 파일 업로드가 차단될 수 있습니다.',
    //       altType: AlertType.Info,
    //     }),
    //   );
    // }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === 'inPut') {
      setInputData(value);
    } else {
      setOutputData(value);
    }
  };

  /**
   * input/ouput rag 추가
   */
  const handleAddRag = async () => {
    const obj = {
      partitionKey: formData.partitionKey,
      deleted: formData.deleted,
      companyCode: formData.companyCode,
      inPut: inputData,
      outPut: outputData,
      customPluginId: formData.id as string,
    };

    setRagContentsData((prev) => [...prev, { ...obj, status: 'learning' }]);
    setInputData('');
    setOutputData('');

    try {
      const res = await plugin.putRagContent(obj);
      if (res.statusCode === 200) {
        setRagContentsData((prev) =>
          prev.map((r) => (r.inPut === inputData && r.outPut === outputData ? res.value : r)),
        );
      } else {
        dispatch(
          showAlertToast({
            altMessage: String(res.value.message),
            altType: AlertType.Error,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="dsx-title-bar">
        <Heading as="h2" size="title3" weight="semibold">
          학습 데이터 등록
        </Heading>
        <Text size="body2">파일을 첨부하거나, 데이터 간편 등록을 통해 학습 데이터를 직접 입력해 보세요.</Text>
      </div>
      <Form variant="vertical" className="form-field" onSubmit={handleFormSubmit}>
        <FormItem label="파일 첨부" desc="학습시키고 싶은 데이터가 있다면 첨부해 주세요.">
          <FileUploader
            ref={fileRef}
            allowDrop
            allowFileType={['.pdf', '.xlsx', '.jpg', '.png', '.doc', '.docx']}
            uploadMsg={
              <>
                <IconUpload size={40} variant={dragging ? 'primary' : 'normal'} />
                <Text size="body3" weight="medium">
                  내 PC에서 파일을 첨부하거나
                  <br />
                  마우스로 문서를 드래그하여 넣어주세요.
                </Text>
                <Text size="caption1" accent="alternative">
                  *25MB 이하의 PDF, XLSX, JPG, PNG, DOC, DOCX 파일만 업로드 가능합니다.
                </Text>
              </>
            }
            onChange={(files: File[]) => {
              void fileChange(files);
            }}
            onRemove={fileDelete}
            onDrag={(bool: boolean) => {
              setDragging(bool);
            }}
            buttonProps={{ variant: dragging ? 'primary' : 'secondary', text: '내 PC 파일 업로드' }}
            hideFileList
            maxFileSize={26214400}
          />
          {files.length > 0 && (
            <div className="upload-container">
              <Heading as="strong" size="body3">
                업로드 파일
              </Heading>
              <ul className="upload-fileList">
                {files.map((file, idx) => (
                  <li className="upload-fileList-item" key={idx}>
                    <span className="file-icon">
                      <Image url={getFileIcon(file.type)} />
                    </span>
                    <span className="file-name">{file.name}</span>
                    <span className="file-size">{bytesToSize(file.size)}</span>
                    <div className="upload-fileList-controls">
                      <Badge
                        variant="tint"
                        size="large"
                        round
                        accent={
                          file.status === 'uploaded' || file.status === 'learningSuccess'
                            ? 'info'
                            : file.status === 'uploadFailed' || file.status === 'learningFailed'
                              ? 'negative'
                              : 'normal'
                        }
                      >
                        {statusToKorean[file.status]}
                        {(file.status === 'uploading' || file.status === 'learning' || file.status === 'removing') && (
                          <Icon name="loading" />
                        )}
                      </Badge>

                      {file.status === 'uploading' ? (
                        <IconButton
                          name="close"
                          className="file-delete"
                          size="large"
                          // onClick={() => {
                          //   onCancelUpload(file);
                          // }}
                        >
                          업로드 취소
                        </IconButton>
                      ) : (
                        <IconButton
                          name="delete"
                          className="file-delete"
                          size="large"
                          onClick={() => {
                            void handleFileRemove(file, idx);
                          }}
                          disabled={file.status === 'learning'}
                        >
                          파일삭제
                        </IconButton>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </FormItem>
        <FormItem label="데이터 간편 등록" desc="학습 데이터를 간단하게 입력해 보세요.">
          <div className="dsx-cont-box learning-field">
            <div className="l-field">
              <label htmlFor="learnInput" className="l-label">
                Input
              </label>
              <Textarea
                id="learnInput"
                size="xlarge"
                placeholder="Input을 작성해주세요"
                maxLength={2000}
                showCount
                name="inPut"
                value={inputData}
                onChange={handleChange}
              />
            </div>
            <div className="l-field">
              <label htmlFor="learnOutput" className="l-label">
                Output
              </label>
              <Textarea
                id="learnOutput"
                size="xlarge"
                placeholder="Output을 작성해주세요"
                maxLength={2000}
                showCount
                name="outPut"
                value={outputData}
                onChange={handleChange}
              />
            </div>
            <ButtonArea align="end">
              <Button variant="normal" onClick={() => void handleAddRag()} disabled={!isRegistable}>
                추가
              </Button>
            </ButtonArea>
          </div>
          {/* 파일 등록 데이터 */}
          {ragContentsData.length > 0 && (
            <PluginLearningData
              ragContentsData={ragContentsData}
              setRagContentsData={setRagContentsData}
              formData={formData}
            />
          )}
        </FormItem>
      </Form>
      <ButtonArea align="end">
        <div className="dsx-side-left">
          <Button variant="outline" size="large" prefixIcon="chevronLeft" onClick={handlePrev}>
            이전
          </Button>
        </div>
        <Button type="submit" variant="primary" size="large" suffixIcon="chevron" onClick={handleFormSubmit}>
          다음
        </Button>
      </ButtonArea>
    </>
  );
};

export default PluginCreateDtl;
