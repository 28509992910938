import { Icon } from '../Icon';
import { AlertType } from '../../libs/models/AlertType';

interface IErrorProps {
  message: string | undefined;
  type?: AlertType;
}

export const Error: React.FC<IErrorProps> = ({ message, type = AlertType.Error }) => {
  return (
    <p className={`toast_message toast-error`} role="alert">
      <Icon name={type} />
      {message}
    </p>
  );
};
