import Image from "../components/Image"

export const PageNotFound = () => {
    return (
        <div className="ds-page">
            <div className="ds-page-error">
                <Image url="etc-icons/img_error.png" />
                <div className="error-content">
                    <div className="error-title">오류가 발생했습니다.</div>
                    <div className="error-message">
                        페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다. <br/>
                        입력하신 주소가 정확한지 다시 한 번 확인해주세요.
                    </div>
                </div>
            </div>
        </div>
    )
}