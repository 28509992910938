import React, { useState } from 'react';

import { Button } from '../../components/Button';
import { ChatModelSelect } from './unit/ChatModelSelect';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { setSelectedModel } from '../../redux/features/app/aiModelsSlice';
import { useChat } from '../../libs/hooks/useChat';

interface ChatHeaderOnMobileProps {
  toggleCollapse: () => void;
}

export const ChatHeaderOnMobile: React.FC<ChatHeaderOnMobileProps> = ({ toggleCollapse }) => {
  const chat = useChat();
  const dispatch = useAppDispatch();

  // 버전 선택 팝오버
  const [isOpen, setIsOpen] = useState(false);
  const { models, selectedModel } = useAppSelector((state: RootState) => state.models);

  const changeSelection = (modelName: string) => {
    dispatch(setSelectedModel(modelName));
  };

  return (
    <header className="chat-header-mobile">
      <Button control textBlind icon="menu-burger" onClick={toggleCollapse}>
        메뉴
      </Button>
      <ChatModelSelect
        data={models}
        selectedName={selectedModel.model}
        onSelect={changeSelection}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        message=""
      />
      <Button
        control
        textBlind
        icon="new-chat"
        onClick={() => {
          chat.createChatRoom(false);
        }}
      >
        새로운 채팅
      </Button>
    </header>
  );
};
