import React from 'react';

interface PageTitleProps {
  title: string;
  desc: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, desc }) => {
  return (
    <div className="page-title">
      <h2 className="title" dangerouslySetInnerHTML={{ __html: title }} />
      <p className="desc">{desc}</p>
    </div>
  );
};

export default PageTitle;
