import { useState, useEffect, forwardRef, ChangeEvent } from 'react';

interface TextareaProps {
  placeholder?: string;
  maxRows?: number;
  typingState?: (isTyping: boolean) => void;
  setValue?: (value: string) => void;
  disabled?: boolean;
  handleKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const ChatTextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ placeholder = '', maxRows = 8, typingState, setValue, disabled = false, handleKeyDown }, ref) => {
    const [rows, setRows] = useState(1);
    const [isTyping, setIsTyping] = useState(false);
    const textareaLineHeight = 24; // row 기준

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const previousRows = event.target.rows;
      event.target.rows = 1; // 초기화
      const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);

      if (currentRows === previousRows) {
        event.target.rows = currentRows;
      }

      if (currentRows >= maxRows) {
        event.target.rows = maxRows;
        event.target.scrollTop = event.target.scrollHeight;
      }

      setRows(currentRows < maxRows ? currentRows : maxRows);
      if (setValue) setValue(event.target.value);

      // 입력 중인지 확인
      setIsTyping(event.target.value.length > 0);
    };

    useEffect(() => {
      if (ref && 'current' in ref && ref.current) {
        const currentRows = Math.floor(ref.current.scrollHeight / textareaLineHeight);
        setRows(currentRows < maxRows ? currentRows : maxRows);
      }
    }, [maxRows, ref]);

    useEffect(() => {
      if (typingState) typingState(isTyping);

      return () => {
        if (typingState) typingState(false);
      };
    }, [isTyping, typingState]);

    return (
      <textarea
        ref={ref}
        className="input-text"
        placeholder={placeholder}
        rows={rows}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        style={{ overflowY: rows === maxRows ? 'auto' : 'hidden' }}
      />
    );
  },
);

ChatTextArea.displayName = 'ChatTextArea';
