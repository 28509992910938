import classNames from 'classnames';
import { ChangeEvent, forwardRef, ReactNode, useRef, useState } from 'react';

interface RadioProps {
  id?: string;
  name?: string;
  value?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  size?: string;
  only?: boolean;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      id,
      name,
      value,
      defaultChecked,
      checked,
      disabled,
      invalid,
      size = 'large',
      only,
      className,
      onChange,
      children,
      ...props
    },
    ref,
  ) => {
    const [privateChecked, setPrivateChecked] = useState(defaultChecked ?? false);
    const { current: controlled } = useRef(checked !== undefined);
    const isChecked = controlled ? checked : privateChecked;

    const radioId = useRef(id ?? `radio-${Math.random().toString(36).substring(2, 6)}`).current;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (!controlled) {
        setPrivateChecked(event.target.checked);
      }
      if (onChange) {
        onChange(event);
      }
    };

    return (
      <div className={classNames('dsx-Radio', size && `dsx-Radio--${size}`, invalid && 'is-invalid', className)}>
        <input
          type="radio"
          id={radioId}
          name={name}
          className="dsx-Radio-input"
          value={value}
          checked={isChecked}
          disabled={disabled}
          onChange={handleChange}
          ref={ref}
          {...props}
        />
        <label htmlFor={radioId} className={classNames('dsx-Radio-label', only && 'dsx-blind')}>
          {children}
        </label>
      </div>
    );
  },
);

Radio.displayName = 'Radio';
