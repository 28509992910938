import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Button } from './Button';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';
import { hideAlertModal } from '../redux/features/app/appSlice';

interface IAlertModalnProps {
  buttonName?: string;
  onClick?: () => void;
}

export const AlertModal: FC<IAlertModalnProps> = ({ buttonName = '삭제', onClick }) => {
  const dispatch = useAppDispatch();

  const { altModalVisible, altModalMessage } = useAppSelector((state: RootState) => state.app);

  if (!altModalVisible) return null;

  const modalRoot = document.getElementById('modal-root');
  if (!modalRoot) {
    throw new Error('Modal root element not found');
  }

  return createPortal(
    <div className={classNames('ds-modal-overlay is-show')}>
      <div className="ds-modal ds-modal-alert" role="alertdialog">
        <div className="alert-content">
          <h2 className="alert-title">{altModalMessage.altHeader}</h2>
          <p className="alert-message" dangerouslySetInnerHTML={{ __html: altModalMessage.altMessage }}></p>
        </div>
        <div className="alert-footer">
          <Button
            theme={'outline'}
            onClick={() => {
              dispatch(hideAlertModal());
            }}
          >
            취소
          </Button>
          <Button
            theme={'primary'}
            onClick={() => {
              onClick;
              dispatch(hideAlertModal());
            }}
          >
            {buttonName}
          </Button>
        </div>
      </div>
    </div>,
    modalRoot,
  );
};
