import { SetStateAction, useRef, useState } from 'react';
import { useChat } from '../../../libs/hooks/useChat';
import { AlertType } from '../../../libs/models/AlertType';
import { useAppDispatch } from '../../../redux/app/hooks';
import { showAlertToast } from '../../../redux/features/app/appSlice';
import { editConversationTitle } from '../../../redux/features/conversations/conversationsSlice';

interface IEditChatNameProps {
  name: string;
  chatId: string;
  exitEdits: () => void;
}

export const EditChatName: React.FC<IEditChatNameProps> = ({ name, chatId, exitEdits }) => {
  const dispatch = useAppDispatch();
  const chat = useChat();
  const [title = '', setTitle] = useState<string | undefined>(name);

  // 입력 필드가 렌더링된 후 포커스 설정
  const inputRef = useRef<HTMLInputElement>(null);
  setTimeout(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, 10);

  const onSaveTitleChange = async () => {
    if (name !== title) {
      await chat.editChat(chatId, title).then(() => {
        dispatch(editConversationTitle({ id: chatId, newTitle: title }));
      });
    }
    exitEdits();
  };

  const handleSave = () => {
    onSaveTitleChange().catch(() => {
      const errorMessage = `채팅방 이름을 수정하는 중에 문제가 발생했어요. 다시 시도해주세요.`;
      dispatch(showAlertToast({ altMessage: errorMessage, altType: AlertType.Error }));
    });
  };

  const handleChange = (e: { target: { value: SetStateAction<string | undefined> } }) => {
    setTitle(e.target.value);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div className="chat-edit-name">
      <input
        ref={inputRef}
        id={`input-${chatId}`}
        type="text"
        value={title}
        className="edit-input"
        title="이름 수정"
        onChange={handleChange}
        onBlur={handleSave}
        onKeyDown={handleKeyDown}
        // autoFocus
      />
    </div>
  );
};
