import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import classNames from 'classnames';

interface IFileUploaderProps {
  onFileUpload: (files: File[]) => void;
  className?: string;
  style?: React.CSSProperties;
}

const FileUploader: React.FC<IFileUploaderProps> = ({ onFileUpload, className, style }) => {
  const [fileList, setFileList] = useState<File[]>([]);

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleFileAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files as Iterable<File> | ArrayLike<File>);
    addFiles(files);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    addFiles(files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const addFiles = (newFiles: File[]) => {
    // 중복 파일 검사 및 필요한 처리 추가 가능
    // const filteredFiles = newFiles.filter((newFile) => {
    //   return !fileList.some((existingFile) => existingFile.name === newFile.name);
    // });

    setFileList((prevFileList) => [...prevFileList, ...newFiles]);
    onFileUpload(newFiles); // 상위 컴포넌트로 전달
  };

  const removeFile = (fileToRemove: File) => {
    const updatedFileList = fileList.filter((file) => file !== fileToRemove);
    setFileList(updatedFileList);
  };

  return (
    <div
      className={classNames('file-uploader', className)}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      style={style}
    >
      <input id="file-input" type="file" multiple onChange={handleFileAdd} ref={fileInputRef} />
      <div className="drop-area uploader-inner">
        <Image url="etc-icons/ico_data_upload.svg" />
        <strong>마우스로 문서를 드래그하여 넣어주세요.</strong>
        <p className="desc">5MB이하의 PDF, DOCX, XLSX, PPTX, JPG, PNG 파일만 업로드 가능합니다.</p>
      </div>

      {fileList.length > 0 && (
        <div className="file-list" style={{ display: 'none' }}>
          <strong>Files:</strong>
          <ul>
            {fileList.map((file, index) => (
              <li key={index}>
                {file.name}
                <button
                  onClick={() => {
                    removeFile(file);
                  }}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

FileUploader.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
};

export default FileUploader;
