import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

interface IImageProps {
  className?: string;
  url: string;
  alt?: string;
}

//프로젝트 내 모든 이미지파일 가져오기. useEffect 내에서 동적 import 시 오류남

const Image: React.FC<IImageProps> = ({ className, url, alt = '' }) => {
  const [image, setImage] = useState<string>('');

  useEffect(() => {
    const importImage = () => {
      try {
        import(`../assets/${url}`)
          .then((images: typeof import('*.svg') | typeof import('*.png')) => {
            setImage(images.default);
          })
          .catch((error) => {
            console.error('Image loading error:', error);
          });
      } catch (error) {
        console.error('Image loading error:', error);
      }
    };
    importImage();
  }, [url]);

  return <img src={image} alt={alt} className={className} />;
};

export default Image;
