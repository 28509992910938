import { FC } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Image from './Image';
import { Avatar, Badge, Heading, Popover, Text } from './base';
import { useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';

interface IHeaderProps {
  title: string;
  menus: string[];
}

const Header: FC<IHeaderProps> = ({ title, menus }) => {
  const location = useLocation();
  const { user } = useAppSelector((state: RootState) => state.users);

  return (
    <header className="header">
      <div className="gnb">
        <Link to="#" role="button" className="logo">
          <Image url="images/logo_works.svg" />
          <strong className="logo-name">{title}</strong>
        </Link>
        <nav className="nav">
          <ul className="menu-list">
            {menus.map((item) => (
              <li key={item}>
                <Link
                  to={`/makers/${item.toLowerCase()}`}
                  role="button"
                  className={`dth1 ${location.pathname === `/makers/${item.toLowerCase()}` ? 'is-active' : ''}`}
                >
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        {/* <div className="util">유틸메뉴 준비중</div> */}

        <div className="util">
          <Popover
            trigger={
              <button className="profile-trigger">
                <Avatar
                  size="2"
                  variant="user"
                  label=""
                  src={`https://gspaceapi.kt.co.kr/upload/profile/${user.userId}.jpg`}
                  userAvatar={true}
                />
              </button>
            }
            position={['bottom', 'end']}
            gap={24}
          >
            <div className="dsx-Popover-inner profile-popover">
              <div className="profile-user">
                <Avatar
                  size="7"
                  variant="user"
                  label=""
                  src={`https://gspaceapi.kt.co.kr/upload/profile/${user.userId}.jpg`}
                  userAvatar={true}
                  largeUserAvatar={true}
                />
                <div className="user-name">
                  <Heading size="title2">{user.displayName}</Heading>
                  <Badge size="large" accent="primary">
                    {user.titleName}
                  </Badge>
                </div>
                <div className="user-team">
                  <Text accent="neutral"> {user.positionName}</Text>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </header>
  );
};

export default Header;
